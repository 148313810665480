import React from "react";
import { ITeam } from "../../model/ITeam";
import { TeamItem } from "../TeamItem/TeamItem";
import { Title } from "../Title/Title";
import styles from "./Team.module.css";
import { Anchor } from "../Anchor/Anchor";

export const Team: React.FC<{team: ITeam[]}> = ({team}) => {

    return (
        <div className={styles.teamOuterWrapper} >  
            <Anchor idTag="team"/>
           
           <Title text="Unser Team" white />  
           <div className={styles.teamWrapper} >
            {
                team.map((member, index) => 
                <TeamItem teamMember={member} key={index}/>)
            }               
           </div>
           
    </div>);

}
