import { Divider } from "antd";
import React from "react";
import { SafetyItem } from "../SafetyItem/SafetyItem";
import { Title } from "../Title/Title";
import styles from "./SafetyComponent.module.css";

export const SafetyComponent: React.FC<{}> = ({}) => {

    return (
        <div className={styles.safetyWrapper} id="safety">
            <Title text="Sicherheitshinweise"/>
            <a href="https://daurer.de/datein/Schnupperschießen_Teilnahmebedingungen_2024_1.pdf" className={styles.safetyConditionsDownload} target="_blank" download><div>Teilnahmebedingungen</div></a>
            <div className={styles.saftyGridWrapper}>
                <div className={styles.saftyLeft}>
                    <Divider orientation="left" className={styles.divider} style={{border:'#cfcfcf', color: "#cfcfcf"}}>{"Bedingungen für Schützen".toUpperCase()}</Divider>  
                    <SafetyItem text={"Mindestalter: 18 Jahre"}/>
                    <SafetyItem text={"Kein Waffenschein, WBK oder absolvierter Lehrgang notwendig."}/>
                    <SafetyItem text={"Keine Vorerfahrung nötig, alles wird erklärt."}/>
                    <SafetyItem text={"Begleitung darf gerne mitkommen, zuschauen, Bilder machen und filmen."}/>
                    <SafetyItem text={"Selbstverschuldete Sachschäden sind nicht mitversichert."}/>  
                    <SafetyItem text={"Die Teilnahme während einer Schwangerschaft geschieht auf eigene Verantwortung über die Gesundheit des Kindes."}/> 
                </div>
                <div className={styles.saftyRight}>
                    <Divider orientation="left" style={{border:'#cfcfcf', color: "#cfcfcf"}}>{"Sicherheitsvorschriften".toUpperCase()}</Divider> 
                    <SafetyItem text={"Den Aufforderungen der Schießstandaufsicht ist Folge zu leisten."}/>
                    <SafetyItem text={"An der Waffe: Der Lauf zeigt in eine schusssichere Richtung, der Finger ist stets fern vom Abzug bis geschossen wird und die Augen behalten großen Abstand zum Zielfernrohr."}/>
                    <SafetyItem text={"Festes Schuhwerk und lange Kleidung sind empfohlen."}/>
                    <SafetyItem text={"Lange Haare empfehlen sich zusammenzubinden."}/>
                    <SafetyItem text={"Die Teilnahme ist ausschließlich nüchtern von Alkohol oder berauschender Substanzen möglich."}/>  
                </div>
            </div>
           
        </div> );

}
