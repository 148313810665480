import { AimOutlined, RightOutlined } from "@ant-design/icons";
import { Checkbox, DatePicker, Form, Input, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { IAppSettings } from "../../../model/IAppSettings";
import { createEventBooking, IEventBooking } from "../../../model/IEventBooking";
import { IPackage, IPeopeCosts } from "../../../model/IPackage";
import { Button } from "../../Button/Button";
import styles from "./BookingModal.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { sendEmail } from "../../../shared/emailService";
import Media from "react-media";
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/de_DE';
import { formatDatePickerDate } from "../../../shared/formatter";


export const BookingModal: React.FC<{visible: boolean, onCancel: () => void, packageItem: IPackage, settings: IAppSettings, selectedPeople: IPeopeCosts}> = ({visible, onCancel, packageItem, settings, selectedPeople}) => {
     
    
    const { TextArea } = Input;    
    const { Option } = Select;
    const [form] = Form.useForm();


    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [reCaptchaValue, setReCaptchaValue] = useState(null as string | null);


    const options = packageItem?.peopleCosts.map((peoCost, index) => (<Option value={peoCost.count}>{peoCost.count}</Option>));
    const dateOptions = settings.eventSettings.possibleBookingDays.map((date) => new Date(date)).filter((date) => date > new Date()).map((date, index) => (<Option value={formatDatePickerDate(date)}>{formatDatePickerDate(date)}</Option>));

    const checkIsValid = () => {
        const fields = form.getFieldsValue(true);    
        const emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);    
        setIsValid(fields.name != "" &&
        emailRegEx.test(fields.email) &&
        fields.phone != "" &&
        fields.privacyAccepted );              
    }

    const onPanelClose = () => {
        form.resetFields()
        onCancel();
    }

    const onSendRequest = (booking: IEventBooking) => {
        booking.packageName = packageItem.title;
        booking.packageId = packageItem.id;
        booking.timestamp = new Date().toLocaleString();

        sendEmail(settings, booking);
        onPanelClose();
    }
    

    return (<Modal
            title={(<div className={styles.modalTitle}>Paket {packageItem.title} anfragen</div>)}
            centered
            visible={visible}
            onCancel={() => onPanelClose()}
            width={"80%"}
            footer={null}
            className={styles.eventModal}
            destroyOnClose
            
        >           
          <div className={styles.modalContentWrapper}>           
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={createEventBooking({peopelCount: selectedPeople.count})}
            onFinish={(values) => onSendRequest(values)}
            autoComplete="off"
            onFieldsChange={() => checkIsValid()}
            className={styles.modalForm}
            
            >
            <Form.Item
                label="Vollständiger Name"
                name="name"
                rules={[{ required: true,  message: ""  }]}
            >
                <Input />
            </Form.Item>            
            <Form.Item
                label="E-Mail"
                name="email"
                rules={[
                    { type: 'email', message: 'E-Mail Adresse nicht gültig' },
                    { required: true, message: "" }]}
            >
                <Input type={"email"}/>
            </Form.Item>
            <Form.Item
                label="Telefonnummer"
                name="phone"
                rules={[{ required: true, message: "" }]}
            >
                <Input type={"tel"}/>
            </Form.Item>
            <Form.Item
                label="Personen"
                name="peopelCount"
                rules={[{ required: true, message: "" }]}
            >
               <Select>
                    {options}
                </Select>
            </Form.Item>
            <Form.Item
                label="Terminvorschläge"
                name="picketDate"
            >
                <Select allowClear>
                    {dateOptions}
                </Select>
            </Form.Item>   
            <Form.Item
                label="oder individueller Wunschtermin"
                name="desiredDate"
            >               
                <Input />
            </Form.Item>              
            <Form.Item
                label={"Weitere Wünsche"}
                name="comment"
            >
                 <TextArea
                    autoSize={{ minRows: 5, maxRows: 8 }}
                />
           </Form.Item>
           <Form.Item name="privacyAccepted" 
        valuePropName="checked" rules={[{ required: true, message: "" }]}>
                <Checkbox onChange={(event) => setButtonDisabled(!event.target.checked) }><span style={{color: "#ff4d4f"}}>*</span> Ich bin damit einverstanden, dass meine hier eingegebenen Daten ausschließlich zur Weiterverarbeitung und Kontaktaufnahme durch die Firma Daurer zur Vereinbarung eines Schießtermines genutzt werden. Auf Anfrage werden Ihre Daten aus unserem System gelöscht.</Checkbox>
            </Form.Item>
            
            <Form.Item > 
            <Media queries={{
          small: " (max-width: 444px)",
          large: " (min-width: 445px)"
        }}>
          {matches => (
            <>
            
              {matches.large && 
                <ReCAPTCHA
                size={ "normal"}
                 sitekey={settings.eventSettings.googleCaptchaKey}
                 onChange={(value) => {setReCaptchaValue(value)}}
            />}
              {matches.small && 
                <ReCAPTCHA
                size={ "compact"}
                 sitekey={settings.eventSettings.googleCaptchaKey}
                 onChange={(value) => {setReCaptchaValue(value)}}
            />}
               
            </>
          )}
        </Media>          
            </Form.Item>
            <Form.Item shouldUpdate >                
                <div className={styles.modalButtonWrapper}><Button onClick={() => form.submit()} text="Schießtraining anfragen" dark disabled={!isValid || buttonDisabled || reCaptchaValue == null }/></div>
            </Form.Item>
            </Form>
          </div>
        </Modal> 
    );

}
