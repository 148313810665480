import {ShoppingCartOutlined } from "@ant-design/icons";
import { Badge, Divider, Select, Tag, Tooltip } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAppSettings } from "../../../model/IAppSettings";
import { createWeapon, IWeapon } from "../../../model/IWeapon";
import { Button } from "../../Button/Button";
import { Title } from "../../Title/Title";
import { BookingModalIndividuall } from "../BookingModalIndividuall/BookingModalIndividuall";
import { PackageListWeapoItem } from "../PackageListWeapoItem/PackageListWeapoItem";
import styles from "./PackageListIndividuall.module.css";
import { IndiviualPricingContext } from "../../../App";
import { formatTime, formatToEuro } from "../../../shared/formatter";
import { IndividuellPricingModal } from "../../IndividuellPricingModal/IndividuellPricingModal";
import { claculateTotalPrice, getDuration, getMunitonCount } from "../../../shared/individuellPackageCalculator";

export const PackageListIndividuall: React.FC<{weapons: IWeapon[], settings: IAppSettings, pickedWeapons: IWeapon[], setPickedWeapons: (weapon: IWeapon[]) => void }> = ({weapons, settings, pickedWeapons, setPickedWeapons}) => {
   


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPricngModalOpen, setPricingModalOpen] = useState(false);
    const [peopleCount, setPeopelCount] = useState(Number((new URLSearchParams(window.location.search)).get("pp") ?? "1"));
    const [totalPrice, setTotalPrice ] = useState(0);
    const navigation = useNavigate()
    
    const { Option } = Select;
    const indiviualPricingContext = useContext(IndiviualPricingContext);

    

    useEffect(()=> 
        {
            setTotalPrice(claculateTotalPrice(indiviualPricingContext, pickedWeapons, peopleCount));
            updateURLParams(pickedWeapons, peopleCount);
            
        }, [pickedWeapons, peopleCount])

    const filterWeapons = (): IWeapon[] => {        
        const filterdWeapons = settings.eventSettings.weaponsIndividuall.map(weId => {
            const index = weapons.findIndex(w => w.id == weId);
            return index!= -1 ? weapons[index] : createWeapon();
            })
        return filterdWeapons;       
    }    
    const selectableWeapons = filterWeapons();

    const updatePeopleCount = (newCount: number) => {
        setPeopelCount(newCount);
        updateURLParams(pickedWeapons, newCount);
    }

    const addWeapon = (weapon: IWeapon) => {
        let listCopy = _.cloneDeep(pickedWeapons);
        listCopy.push(weapon);
        setPickedWeapons(listCopy);
        updateURLParams(listCopy, peopleCount);        
        
    }

    const removeWeapon = (id: number) => {
        let listCopy = _.cloneDeep(pickedWeapons);
        listCopy = listCopy.filter(we => we.id != id);
        setPickedWeapons(listCopy);  
        updateURLParams(listCopy, peopleCount);    
    }

    const updateURLParams = (newList: IWeapon[], ppCount: number) => {
        var arrStr = newList.map(we => we.id).join("|");
        navigation({
            pathname: '',
            search: newList.length == 0 ? "" : `?ws=${arrStr}&pp=${ppCount}`,
          });
    }

    const clearBasked = () => {
        setPickedWeapons([])
        updateURLParams([], 1)
    }

    const infoTextShorter = "Klicke Deine Wunschwaffen an (mindestens 3) und gebe Deine Kontaktdaten ein.\nWir melden uns schnellstmöglich bei Dir, um einen Wunschtermin zu reservieren.";
    const options = indiviualPricingContext.peopleCountSpecs.map((peoCount, index) => (<Option value={peoCount.count}>{peoCount.count}</Option>));
    
    return (<>     
            <BookingModalIndividuall visible={isModalOpen} weapons={pickedWeapons} onCancel={() => setIsModalOpen(false)} settings={settings} removeWeapon={removeWeapon} setPeopelCount={setPeopelCount} clearBasked={() => clearBasked()}/> 
            <IndividuellPricingModal isOpen={isPricngModalOpen} onClose={() => setPricingModalOpen(false)}/>
            <div>                
               
                 <Title text="Individuelle Pakete" subtitle="Dein einzigartiges Erlebnis" dark /> 
                 <div className={styles.infoText}>
                    {infoTextShorter}    <br/>
                    <Tag className={styles.princingTag} color="default" onClick={() => setPricingModalOpen(true)}>Preisübersicht</Tag>                 
                 </div>                             
                    <div className={styles.dividerClass}><Divider />
                    </div>
                    <div className={styles.individualButtonWrapper}> 
                    <div>      
                                        
                        <div className={styles.peopelCountWrapper}>
                            <span className={styles.label}>Personen:</span>
                            <Select  style={{ width: 75 }} value={peopleCount} onChange={(value) => updatePeopleCount(value)}>
                                {options}
                            </Select>
                        </div>
                        <div className={styles.infoWrapper}>
                            <div>                                    
                                <div>{pickedWeapons.length >= 3 ? formatTime(getDuration(indiviualPricingContext, pickedWeapons,peopleCount)): "- Stunden"}</div>
                                <div>ca. {pickedWeapons.length >= 3 ? getMunitonCount(indiviualPricingContext, pickedWeapons,peopleCount) : "-"} Schuss pro Person</div>
                            </div>                            
                        </div> 
                        
                        <Divider className={styles.dividerVertical} type="vertical"/>
                        <div className={styles.totalPrice}>{pickedWeapons.length >= 3 ? formatToEuro(totalPrice): "---"} <div>pro Person</div></div>
                        <div className={styles.buttonWrapper}>
                        <Tooltip placement="bottomLeft" color={"#c43538"}  trigger={pickedWeapons.length < 3 ? "hover": []} title={"Bitte wähle mindestes 3 Waffen aus."} >
                            <Badge count={pickedWeapons.length}style={{ backgroundColor: '#085b27' }}> 
                            <Button disabled={pickedWeapons.length < 3} onClick={() => {setIsModalOpen(pickedWeapons.length >= 3) }} dark icon={<ShoppingCartOutlined />}/>
                            </Badge>
                        </Tooltip> 

                        </div>
                        
                    </div>
                </div>
                    <div className={styles.packageWeponsListWrapper} >   
                        {selectableWeapons.map((item, index) => (<PackageListWeapoItem openShoppingBasket={() => setIsModalOpen(pickedWeapons.length >= 3)} weapon={item} settings={settings} addToBasket={addWeapon} removeFromBasket={removeWeapon} isAdded={pickedWeapons.findIndex(we => we.id == item.id) != -1}/>))}                  
                    </div>                 
            </div>
        
       
    </>);

}

