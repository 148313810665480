
import ReactDOM from 'react-dom';
import ReactGA from "react-ga4";
import { routes } from './routes';

var acceptedAnalytics = false

export const updateCookieSettings = () => {
    acceptedAnalytics = true;
}

export const initalizieGA = (gaTrackingID: string) => {
    
    ReactGA.initialize(gaTrackingID);
    trackPage()
}

export const trackPage = () => {
    if(acceptedAnalytics && window.location.href.includes(routes.event)){
        ReactGA.send({ hitType: "pageview", page:window.location.pathname + window.location.search });
        
    }
}