import React from "react";
import styles from "./History.module.css";
import { Title } from "../Title/Title";
import { Timeline } from "antd";
import { creatHistroy, IHistory } from "../../model/IHistory";
import { HistoryItem } from "../HistoryItem/HistoryItem";

export const History: React.FC<{history: IHistory[]}> = ({history }) => {

    
    return (
    <div className={styles.historyWrapper}>     
        <Title text="Geschichte" dark />  
        
        <Timeline mode="alternate" pending pendingDot={<></>}>
            {
                [ ...history, creatHistroy( {year: (new Date()).getFullYear().toString()})].map((entry, index) => 
                <Timeline.Item key={index} color="#5A7B5D">
                    <HistoryItem historyItem={entry} index={index}/> 
                </Timeline.Item>
            )}         
            
        </Timeline>
    
    </div>);

}
