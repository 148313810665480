export interface ICookieSettings{
    functionalAccepted: boolean;
    analyticAccepted: boolean;
    marketingAccepted: boolean;
}

export function createCookieSettings (options?: Partial<ICookieSettings>): ICookieSettings {
    return Object.assign(
        {
            functionalAccepted: false,
            analyticAccepted: false,
            marketingAccepted: false
        },options
    );
}