import { IIndividualPricing } from "../model/IIndividualPricing";
import { IWeapon } from "../model/IWeapon";




export function claculateTotalPrice (indiviualPricing : IIndividualPricing, selectedWeapons: IWeapon[], peopleCount: number):number  
{
    const pricing = indiviualPricing.peopleCountSpecs.find((spec) => spec.count == peopleCount) 
    let price = claculateTotalwithouDiscount(indiviualPricing, selectedWeapons)
    price = price* (pricing?.discountFraction ?? 1);
    return Math.floor(price)
}

export function claculateTotalWeapons (indiviualPricing : IIndividualPricing, selectedWeapons: IWeapon[]):number  
{
    var price = 0;
    selectedWeapons.forEach((weapon) => {
        price += indiviualPricing.weaponCategroies[weapon.priceCategorie].price        
    });
    return Math.floor(price)
}

export function claculateTotalwithouDiscount (indiviualPricing : IIndividualPricing, selectedWeapons: IWeapon[]):number  
{
    let price = indiviualPricing.basePrice;
    price += claculateTotalWeapons(indiviualPricing, selectedWeapons)
    return Math.floor(price)
}

export function getMunitonCount (indiviualPricing : IIndividualPricing, selectedWeapons: IWeapon[], peopleCount: number):number  
{
    const count = indiviualPricing.peopleCountSpecs.find((spec) => spec.count == peopleCount)?.munitonCount
    if(count != undefined){
        if(selectedWeapons.length > 4 &&  count.greaterOrEqual5 != -1) return count.greaterOrEqual5
        return count.under5
    }
    return 0;
}
export function getDuration (indiviualPricing : IIndividualPricing, selectedWeapons: IWeapon[], peopleCount: number):number  
{
    const duration = indiviualPricing.peopleCountSpecs.find((spec) => spec.count == peopleCount)?.duration
    if(duration != undefined){
        if(selectedWeapons.length > 4 &&  duration.greaterOrEqual5 != -1) return duration.greaterOrEqual5
        return duration.under5
    }
    return 0;
}


