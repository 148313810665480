import React from "react";
import styles from "./Title.module.css";

export const Title: React.FC<{ text: string, dark?: boolean, white?: boolean, subtitle?: string}> = ({text, dark, white, subtitle}) => {

    const textColor =white ? "#FFF" :( dark ? "#333" : "#cfcfcf");

    return (   <div className={styles.titleWrapper}>
        <div style={{color: textColor}} className={styles.title}>{text.toUpperCase()}</div>  
        {subtitle ? <div style={{color: textColor}} className={styles.secondTitle}>- {subtitle.toUpperCase()} -</div> : <></>  }   
        </div>             
    );

}
