import React, { useRef } from "react";
import styles from "./HistoryItem.module.css";
import { Image } from "antd";
import { IHistory } from "../../model/IHistory";
import useOnScreen from "../../Helper";
import classNames from "classnames";

export const HistoryItem: React.FC<{historyItem: IHistory, index: number}> = ({historyItem, index }) => {

    
    const thisRef = useRef<HTMLDivElement>(null);  
    
    return (    
       
        <div ref={thisRef}className={classNames(styles.itemWrapper, index % 2 == 0 ? styles.animationLeft: styles.animationRight, useOnScreen(thisRef) && styles.itemSliderStarted)}>
            <span className={styles.yearWrapper}>
                {historyItem.year}
            </span>
            <br/>{historyItem.text}<br/>
            {
                historyItem.imgUrl && 
                <Image
                    
                    height={200}
                    preview={{
                        icons: {
                            right: false,
                            rotateLeft: false,
                            rotateRight: false,
                            left: false,
                            zoomIn: false,
                            zoomOut: false,
                            close: false
                        }}
                    }
                    src={historyItem.imgUrl}
                />
            }            
        </div>

    )

}
