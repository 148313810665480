import { AimOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel, CarouselProps, Divider } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import classNames from "classnames";
import React, { Component } from "react";
import { IPackage } from "../../model/IPackage";
import { IWeapon } from "../../model/IWeapon";
import { formatTime, formatToEuro } from "../../shared/formatter";
import { Button } from "../Button/Button";
import styles from "./ImageCarousel.module.css";

class ImageCarousel extends Component<IImageCarouselProps, IImageCarouselState> {

    public carousel: any = React.createRef();
    
    public imagUrls: string[] = [...this.props.packageItem.imgURLs, ...(this.props.includesWeapons.map((we) => we.imgUrl))];


    constructor(props: IImageCarouselProps) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
      this.carousel = React.createRef();
    }
    next() {
      this.carousel.next();
    }
    previous() {
      this.carousel.prev();
    }
  
    componentDidUpdate() {
      if(this.props.selectedImg != -1){
        this.carousel.goTo(this.props.selectedImg);
      }
    }

    render() {
      return (
        <div style={{width: "100%", height:"100%",  position: "relative"}}>
            <LeftOutlined onClick={this.previous} className={classNames(styles.imgArrow, styles.arrowLeft)}/>
          <Carousel ref={node => (this.carousel = node)} className={styles.carouselStyle}  style={{ height: "100%", position: "relative", margin: "0 auto"}} afterChange={(newIndex) => this.props.onImgChange(newIndex)}>
          {
                   this.imagUrls.map((url, index) => (
                      
                        <img src={url} alt="img" className={styles.itemImg} key={`weaponImg#${this.props.index}.${index}`}/> 
                    )
                    )
                } 
          </Carousel>
          <RightOutlined onClick={this.next} className={classNames(styles.imgArrow, styles.arrowRight)}/> 
        </div>
      );
    }
  }

interface IImageCarouselProps {
    packageItem: IPackage, 
    index?: number,
    selectedImg: number,
    onImgChange: (newImgIndex: number) => void;
    includesWeapons: IWeapon[]
}

interface IImageCarouselState {
    
}

  export default ImageCarousel;