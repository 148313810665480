import React from "react";
import { IPackage } from "../../model/IPackage";
import styles from "./HomeEvent.module.css";
import _, { round } from 'lodash';
import { PackageListItem } from "../../components/PackageComponents/PackageListItem/PackageListItem";
import { IReview } from "../../model/IReview";
import { Title } from "../../components/Title/Title";
import { IAppSettings } from "../../model/IAppSettings";
import { SafetyComponent } from "../../components/SafetyComponent/SafetyComponent";
import { GeneralInfoComponent } from "../../components/GeneralInfoComponent/GeneralInfoComponent";
import { IWeapon } from "../../model/IWeapon";
import { useNavigate } from "react-router-dom";
import { LegalNoticeComponent } from "../../components/LegalNoticeComponent/LegalNoticeComponent";
import { Introduction } from "./Introduction/Introduction";
import { Directions } from "../../components/Directions/Directions";
import { Review } from "../../components/Reviews/Review";
import { routes } from "../../routes";

export const HomeEvent: React.FC<{packages: IPackage[], reviews: IReview[], settings: IAppSettings, weapons: IWeapon[], setNavigationVisible: (newState: boolean) => void, introductionText: string}> = ({packages, reviews, settings, weapons, setNavigationVisible, introductionText}) => {

    const mostPickedPackages = _.orderBy(packages.filter(pack => pack.mostPicketIndex != -1), ['mostPicketIndex']);

    let navigate = useNavigate();
    const onPackageItemClick= (packageItem : IPackage) => {
        setNavigationVisible(true);
        navigate(`${routes.eventPackages}/${packageItem.title}?id=${packageItem.id}`, {replace: true});
        
    }

    return (
    <div className={styles.homeWrapper}> 
        <Introduction introductionTextLocalize={introductionText}/>    
        <GeneralInfoComponent/>
        <Title text="MEIST GEWÄHLTE PAKETE" dark />    
        <div className={styles.packageWapper}>          
            {mostPickedPackages.map((item, index) => (<div key={"mostPickedPack#" + index} className={styles.itemWrapper}><PackageListItem packageItem={item}index={index} stacked weapons={weapons} onHomeSelect={onPackageItemClick}/></div>))}
        </div>   

        <SafetyComponent/> 
        <Directions/>
        <Review reviews={reviews}/>
        <LegalNoticeComponent/>
    </div>);

}
