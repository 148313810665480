import _ from "lodash";
import { IWeaponRef } from "./IWeapon";

export interface IPackage {
    id: string,
    title: string,
    shortInfo: string,
    additionalInfo: string[],
    weapons: IWeaponRef[],
    imgURLs: string[],
    mostPicketIndex: number,
    peopleCosts: IPeopeCosts[],
}


export interface IPeopeCosts{
    count: number;
    price: number;
    duration: number, //in Minuten

}

export function createPackage (options?: Partial<IPackage>): IPackage {
    return Object.assign(
        {
            id: "",
            title: "",
            price: -1,
            shortInfo: "",
            additionalInfo: [],
            weapons: [],
            duration: 0,
            people: 0,
            imgURLs: [],
            mostPicketIndex: -1,
            peopleCosts: []
        },options
    );
}

export function getPeopleCosts (pack: IPackage, people?: number): IPeopeCosts {
    if(people == undefined){
        return _.first(pack.peopleCosts) ?? createPeopeCosts();
    }
    const index =  pack.peopleCosts.findIndex(pa => pa.count == people);
    return pack.peopleCosts[index] ?? createPeopeCosts();
}

export function createPeopeCosts (options?: Partial<IPeopeCosts>): IPeopeCosts {
    return Object.assign(
        {
            price: -1,
            duration: 0,
            count: 0,
        },options
    );
}