import { Divider } from "antd";
import React from "react";
import { SafetyItem } from "../SafetyItem/SafetyItem";
import { Title } from "../Title/Title";
import styles from "./LegalNoticeComponent.module.css";
import { GeneralInfoItem } from "../GeneralInfoItem/GeneralInfoItem";

export const LegalNoticeComponent: React.FC<{}> = ({}) => {

    return (
        <div className={styles.legalNoticeWrapper} id="legalNoticeWrapper">
            <Title text="Rechtliche Hinweise" white/>
            <div className={styles.legalNoticeGridWrapper}>
                <div className={styles.legalNoticeLeft}>
                    <GeneralInfoItem title="Waffen" text="All unsere Waffen sind in Deutschland legal und zugelassen. Sie weisen ausnahmslos folgende Eigenschaften auf:"/>
                    <SafetyItem text={"Sie besitzen ein gültiges Beschusszeichen"}/>
                    <SafetyItem text={"Sie sind eingetragen im digitalen nationalen Waffenregister (NWR) unter der Waffenhandelslizenz von Waffen Daurer (Inhaber: Thomas Daurer)"}/>
                    <SafetyItem text={"Sie halten die gültigen Magazinkapazitäten ein"}/>
                    <SafetyItem text={"Sie sind einzelladend oder halbautomatisch selbstladend – keine der Waffen lädt vollautomatisch durch"}/>
                    <SafetyItem text={"Waffenrechtlicher Überlasser (Vorbesitzer) der Waffen ist entweder direkt der Hersteller, der Importeur oder ein zugelassener Großhändler"}/>
                    <SafetyItem text={"Sie sind für den zivilen Markt zugelassen und unterscheiden sich wesentlich und stark von militärischen Waffen"}/>
                </div>
                <div className={styles.legalNoticeRight}>
                    <GeneralInfoItem title="Aufsichtspersonal" text="Unsere Aufsichtspersonen sind mit mehreren Seminaren geschult und damit zugelassene Schießstandaufsichtspersonen. Sie zeichnen sich durch jahrelange Erfahrung in der Branche aus und stehen zu jeder Zeit zur Gewährleistung von Sicherheit und für hilfreiche Tipps an der Seite des Schützen."/>      
                    <GeneralInfoItem title="Schießanlagen" text="Unsere Schießanlagen sind offiziell zertifiziert, zugelassen und stehen unter stetiger Prüfung durch die zuständige Behörde und Sachverständigende."/>   
                    <GeneralInfoItem title="Munition" text="Unsere Munition ist für den zivilen Markt zugelassen und ist C.I.P. zertifiziert."/>   
                </div>
                
            </div>
           
        </div> );

}
