import { ClockCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Banner } from "./components/Banner/Banner";
import { Footer } from "./components/Footer/Footer";
import { Navigation } from "./components/Navigation/Navigation";
import { OppeningHours } from "./components/OppeningHours/OppeningHours";
import { IAppSettings } from "./model/IAppSettings";
import { IHistory } from "./model/IHistory";
import { ITeam } from "./model/ITeam";
import { routes } from "./routes";
import { Home } from "./sites/home/Home";
import { Imprint } from "./sites/imprint/Imprint";
import { CoursesTmp } from "./sites/coursesTmp/CoursesTmp";
import { ICoursesTmp } from "./model/ICoursesTmp";
import { IShootingRange } from "./model/IShootingRange";
import { PrivacyStatement } from "./sites/PrivacyStatement/PrivacyStatement";
import { CookieBanner } from "./components/CookieBanner/CookieBanner";
import { ShootingRange } from "./components/ShootingRangeItem/ShootingRange";
import { IAd } from "./model/IAd";
import { BannerEvent } from "./components/BannerEvent/BannerEvent";
import { IPackage } from "./model/IPackage";
import { IWeapon } from "./model/IWeapon";
import { IReview } from "./model/IReview";
import { IQuestionAndAwnser } from "./model/IQuestionAndAwnser";
import { HomeEvent } from "./sites/HomeEvent/HomeEvent";
import { EventPackages } from "./sites/eventPackages/EventPackages";
import { QuestionsAndAwnsers } from "./sites/QuestionsAndAwnsers/QuestionsAndAwnsers";
import { PackageDetails } from "./components/PackageComponents/PackageDetails/PackageDetails";
import { IIndividualPricing } from "./model/IIndividualPricing";

export const AppComponent: React.FC<IAppComponentProps> = ({ appSettings, history, team, courses, shootingRanges, ads, weapons, packages, questionsAndAwnsers, reviews, individualPricing }) => {
  const [scrollPositionTop, setScrollPositionTop] = useState(document.documentElement.scrollTop == 0 && isHomePage());

  const [cookieBannerOpen, setCookieBannerOpen] = useState(false);

  useEffect(() => {
    if (window.location.pathname != routes.home) {
      notification.close("additional");
      notification.close("basic");
    } else if (isHomePage() && !cookieBannerOpen) {
      showBasicMessage();
      appSettings.homeMessage.show && showAdditionalMessage();
    } else if (cookieBannerOpen) {
      notification.close("additional");
      notification.close("basic");
    }
  }, [window.location.pathname, cookieBannerOpen]);

  const removeHash = () => {
    window.history.replaceState("", document.title, window.location.origin + window.location.pathname + window.location.search);
  };

  useEffect(() => {
    document.getElementById(window.location.hash.substring(1))?.scrollIntoView({
      behavior: "smooth",
    });
    setTimeout(() => {
      removeHash();
      setScrollPositionTop(document.documentElement.scrollTop == 0 && isHomePage());
    }, 0);
  }, []);

  function showBasicMessage(): void {
    const args = {
      key: "basic",
      message: <div style={{ fontWeight: 550 }}>Öffnungszeiten</div>,
      description: <OppeningHours />,
      icon: <ClockCircleOutlined />,
      duration: 0,
      style: {
        background: "#ffffffb8",
        zIndex: 99,
      },
      top: 152,
    };
    notification.open(args);
  }
  function showAdditionalMessage(): void {
    const args = {
      key: "additional",
      message: <div style={{ fontWeight: 550 }}>Aktueller Hinweis</div>,
      description: <div style={{ whiteSpace: "pre-wrap" }}>{appSettings.homeMessage.message}</div>,
      icon: <InfoCircleOutlined />,
      duration: 0,
      style: {
        background: "#ffffffb8",
      },
      top: 152,
    };
    notification.open(args);
  }

  function isHomePage(): boolean {
    const homePaths = [routes.home.toString(), routes.event, routes.eventMuc, routes.eventOB, routes.eventBay];
    return homePaths.includes(document.location.pathname); //|| document.location.pathname == routes.courses
  }

  function onScroll() {
    setScrollPositionTop(document.documentElement.scrollTop == 0 && isHomePage() ? true : false);
  }

  window.addEventListener("scroll", onScroll, false);

  const setNavigationState = (newState: boolean) => {
    setScrollPositionTop(newState);
    document.documentElement.scrollTop = 0;
  };

  const getEventHomeComponent = (introductionText: string) => {
    return <HomeEvent packages={packages} reviews={reviews} settings={appSettings} weapons={weapons} setNavigationVisible={setNavigationState} introductionText={introductionText} />;
  };

  const introductionTexts = [
    "Unsere Schießanlagen befinden sich im Herzen von Rosenheim, südlich von München.",
    "Unsere Schießanlagen befinden sich südlich von München in Rosenheim, gut mit Auto oder Bahn zu erreichen.",
    "Unsere Schießanlagen befinden sich im Süden von Oberbayern in Rosenheim, südlich von München in Richtung Chiemsee.",
    "Unsere Schießanlagen befinden sich im Südosten von Bayern in Rosenheim, nahe München in Richtung Chiemsee.",
  ];

  return (
    <div>
      <BrowserRouter>
        <Navigation isLoggedIn={localStorage.getItem("logedIn") == "yes"} setting={appSettings} scrollPositionTop={scrollPositionTop} setNavigationVisible={setNavigationState} toggleUserModal={(newState: boolean) => {}} />
        <CookieBanner bannerOpen={cookieBannerOpen} setBannerOpen={setCookieBannerOpen} />

        <Routes>
          <Route
            path=""
            element={
              <>
                <Banner title="Waffen Daurer" subTitle="Familienbetrieb seit 1848 in Rosenheim" scrollPositionTop={scrollPositionTop} setNavigationVisible={setNavigationState} />
                <Home history={history} team={team} />
              </>
            }
          />
          <Route path={routes.imprint} element={<Imprint />} />
          <Route path={routes.privacyStatement} element={<PrivacyStatement />} />
          {<Route path={routes.courses} element={<CoursesTmp courses={courses} />} />}
          {/*<Route path={routes.courses} element={<Courses scrollPositionTop={scrollPositionTop} setNavigationVisible={setNavigationState} ads={ads} />} />*/}

          <Route path={routes.shootingRange100} element={<ShootingRange shootingRange={shootingRanges[0]} />} />
          <Route path={routes.shootingRange25} element={<ShootingRange shootingRange={shootingRanges[1]} />} />
          <Route path={routes.shootingRange} element={<Navigate replace to={routes.shootingRange100} />} />
          <Route
            path={routes.event}
            element={
              <>
                <BannerEvent scrollPositionTop={scrollPositionTop} setNavigationVisible={setNavigationState} />
                {getEventHomeComponent(introductionTexts[0])}
              </>
            }
          />
          <Route
            path={routes.eventMuc}
            element={
              <>
                <BannerEvent scrollPositionTop={scrollPositionTop} setNavigationVisible={setNavigationState} placeStr="München" />
                {getEventHomeComponent(introductionTexts[1])}
              </>
            }
          />
          <Route
            path={routes.eventOB}
            element={
              <>
                <BannerEvent scrollPositionTop={scrollPositionTop} setNavigationVisible={setNavigationState} placeStr="Oberbayern" />
                {getEventHomeComponent(introductionTexts[2])}
              </>
            }
          />
          <Route
            path={routes.eventBay}
            element={
              <>
                <BannerEvent scrollPositionTop={scrollPositionTop} setNavigationVisible={setNavigationState} placeStr="Bayern" />
                {getEventHomeComponent(introductionTexts[3])}
              </>
            }
          />
          <Route path={routes.eventPackages} element={<EventPackages packages={packages} weapons={weapons} settings={appSettings} isIndivduall={false} />} />
          <Route path={routes.eventIndividuall} element={<EventPackages packages={packages} weapons={weapons} settings={appSettings} isIndivduall />} />
          <Route path={routes.eventPackages + "/:name"} element={<PackageDetails packages={packages} weapons={weapons} settings={appSettings} />} />
          <Route path={routes.eventQundA} element={<QuestionsAndAwnsers questionsAndAwnsers={questionsAndAwnsers} />} />
          <Route path={routes.event + "/*"} element={<Navigate replace to={routes.event} />} />
          <Route path="overview" element={<Navigate replace to="/" />} />
          <Route path="/" element={<Navigate replace to="/" />} />
        </Routes>
        <Footer setting={appSettings} setCookieBannerOpen={setCookieBannerOpen} />
      </BrowserRouter>
    </div>
  );
};

export interface IAppComponentProps {
  appSettings: IAppSettings;
  history: IHistory[];
  team: ITeam[];
  courses: ICoursesTmp[];
  shootingRanges: IShootingRange[];
  ads: IAd[];
  weapons: IWeapon[];
  packages: IPackage[];
  questionsAndAwnsers: IQuestionAndAwnser[];
  reviews: IReview[];
  individualPricing: IIndividualPricing;
}
