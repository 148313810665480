import React, { useContext, useEffect, useState } from "react";
import styles from "./CookieBanner.module.css";
import { Collapse, Modal, Switch } from "antd";
import { Button } from "../Button/Button";
import {ICookieSettings, createCookieSettings} from "../../model/ICookieSettings";
import _ from "lodash";
import { SettingsContext } from "../../App";

const { Panel } = Collapse;

export const CookieBanner: React.FC<{bannerOpen: boolean, setBannerOpen: (val: boolean) => void}> = ({setBannerOpen, bannerOpen}) => {
    
    const appsettings = useContext(SettingsContext)
    
    const [advanceSettingsOpen, setadvanceSettingsOpen] = useState(false);

    const loadSettings = () => {
        const cookieSettingsJson = localStorage.getItem('cookieSettings');
        var cookieSettings = cookieSettingsJson ? JSON.parse(cookieSettingsJson) : createCookieSettings();
        if(!cookieSettings.functionalAccepted){
            setBannerOpen(true);
        }
        setSettings(cookieSettings);
    }
    const [cookieSettings, setSettings] = useState(createCookieSettings())

    useEffect(() => {
        loadSettings()
    }, [])

    const onAccept = (analyticAccepted: boolean, marketingAccepted:boolean) => {
        var cookieSettingsCopy = _.cloneDeep(cookieSettings);
        cookieSettingsCopy.functionalAccepted = true;
        cookieSettingsCopy.analyticAccepted = analyticAccepted;
        cookieSettingsCopy.marketingAccepted = marketingAccepted;
        setSettings(cookieSettingsCopy);        
        saveSettings(cookieSettingsCopy);        
        ///updateCookieSettings();
        setBannerOpen(false);
        setadvanceSettingsOpen(false)
    }


    const saveSettings = (settings: ICookieSettings) => {
        localStorage.setItem("cookieSettings", JSON.stringify(settings))
    }

    const updateSettings = (analytic: boolean, marketing: boolean) => {
        var cookieSettingsCopy = _.cloneDeep(cookieSettings);
        cookieSettingsCopy.analyticAccepted = analytic;
        cookieSettingsCopy.marketingAccepted = marketing;
        setSettings(cookieSettingsCopy);        
    }

    const textFunctionall = "Diese Cookies sind für den reibungslosen Betrieb der Website erforderlich und können nicht deaktiviert werden."
    const textAnalytic = "Diese Cookies ermöglichen es uns, das Nutzerverhalten auf der Website zu analysieren und die Leistung und Benutzerfreundlichkeit zu verbessern. Sie können entscheiden, ob Sie diese Cookies aktivieren möchten"
    const textMarketing = "Diese Cookies werden verwendet, um Ihnen personalisierte Werbung basierend auf Ihrem Nutzerverhalten anzuzeigen. Sie können entscheiden, ob Sie diese Cookies aktivieren möchten"

    const settingsText = "Wir respektieren Ihre Privatsphäre und bieten Ihnen die Möglichkeit, Ihre Cookie-Einstellungen anzupassen. Bitte beachten Sie, dass das Blockieren einiger Arten von Cookies Auswirkungen auf Ihre Erfahrung auf unserer Website haben kann."

    const settings = (
        <div className={styles.cookieWrapper}>            
            <div className={styles.cookieTitle}>Cookie Einstellungen</div>
            {settingsText}
            <Collapse ghost>
                <Panel header="Essenzielle Cookies" extra={<Switch disabled checked={cookieSettings.functionalAccepted} onClick={(event:any) => { event.stopPropagation();}} className={styles.acceptSwitch}/>} key="1">
                <p>{textFunctionall}</p>
                </Panel>
                <Panel 
                    header="Analytische Cookies" 
                    extra={<div onClick={(event) => { event.stopPropagation();}} >
                        <Switch  checked={cookieSettings.analyticAccepted} className={styles.acceptSwitch}  onChange={() => updateSettings(!cookieSettings.analyticAccepted, cookieSettings.marketingAccepted)}/>
                        </div>} 
                    key="2">
                <p>{textAnalytic}</p>
                </Panel>
                {/*
                    <Panel 
                        header="Marketing-Cookies" 
                        extra={<div onClick={(event) => { event.stopPropagation();}} >
                        <Switch  checked={cookieSettings.analyticAccepted} className={styles.acceptSwitch}  onChange={() => updateSettings(cookieSettings.analyticAccepted, !cookieSettings.marketingAccepted)}/>
                        </div>} 
                        key="3">
                        <p>{textMarketing}</p>
                    </Panel>
                */}               
            </Collapse>

        </div>
    )

    const testInfo = "Wir verwenden Cookies, um Ihnen eine optimale Benutzererfahrung zu bieten. Die gesetzten Cookies dienen sowohl der Funktionalität der Website als auch der Nutzungsanalyse, um unsere Dienste stetig zu verbessern. Dabei verwenden wir sowohl eigene als auch Cookies von Drittanbietern. \n\nMit einem Click auf „Alle Cookies Akzeptieren“ stimmen Sie der Verwendung dieser Cookies und Übermittulung an Dritte zu.\nSie könne Ihre Auswahl jederzeit anpassen."

    const info = (
        <div className={styles.cookieWrapper}>
            <div className={styles.cookieTitle}>Cookie Hinweise</div>
           {testInfo}
        </div>
    )

    return (
        <div className={styles.cookieBannerWrapper}> 
           <div>
           <Modal
                visible={bannerOpen}
                closable={false}
                footer={null}
                width={525}
                className={styles.modalCookie}
                >
                {!advanceSettingsOpen ? info : settings}                
                <div className={styles.buttonWrapper}>
                   {
                    advanceSettingsOpen ? 
                        <Button filled dark text="Ausgewählte Cookies Akzeptieren" onClick={() => {onAccept(cookieSettings.analyticAccepted, cookieSettings.marketingAccepted)}}></Button>:
                        <Button filled dark text="Alle Cookies Akzeptieren" onClick={() => {onAccept(true, true)}}></Button>
                   }
                    <Button dark text="Nur essenzielle Cookies zulassen" onClick={() => {onAccept(false, false)}}></Button>
                    <Button dark text={!advanceSettingsOpen ?"Cookie Einstellungen" : "Zurück"} onClick={() => {setadvanceSettingsOpen(!advanceSettingsOpen)}}></Button>
                </div>  
                <div className={styles.linkWrapper}>
                    <a href={appsettings.urls.mainPageURL + "impressum"} target="_blank" className={styles.footerLink}>Impressum</a>
                    <a href={appsettings.urls.mainPageURL + "datenschutz"} target="_blank" className={styles.footerLink}>Datenschutzerklärung</a>
                </div>              
            </Modal>
           </div>
        </div>
    );

}
 