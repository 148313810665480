import { CheckCircleOutlined, DeleteOutlined, PlusOutlined, StarFilled } from "@ant-design/icons";
import { Badge, Modal, Tag } from "antd";
import React, { useState } from "react";
import Media from "react-media";
import { IAppSettings } from "../../../model/IAppSettings";
import { IPackage } from "../../../model/IPackage";
import { IWeapon } from "../../../model/IWeapon";
import { Button } from "../../Button/Button";
import styles from "./PackageListWeapoItem.module.css";

export const PackageListWeapoItem: React.FC<{ weapon: IWeapon, settings: IAppSettings, addToBasket: (weapon: IWeapon) => void, removeFromBasket: (id: number) => void, isAdded: boolean, openShoppingBasket: () => void}> = ({weapon, settings, addToBasket, isAdded, removeFromBasket, openShoppingBasket}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const onPanelClose = () => {
        setIsModalOpen(false);
    }
    const getRibbonColor = () :string[]=> {
        switch (weapon.priceCategorie){
            case 0:
                return [styles.ribbonBronze,"#7a3818"];
                break;
            case 1:
                return [styles.ribbonSilver,"#444444" ];
                break;
            case 2 : 
                return [styles.ribbonGold, "#3a2c0f"];
                break;
        } 
        return [styles.ribbonBronze,"#7a3818"];
    }

    const color = getRibbonColor() as string[];

    return (<div className={styles.itemWrapper} >      
        <div className={styles.ImgContainer} onClick={() => setIsModalOpen(true)}>
        {
                <Badge.Ribbon text={<StarFilled className={styles.ribbonPremiumStar} /> } className={color[0]} color={color[1]}>
                    <img src={weapon.imgUrl} alt="img" className={styles.weapnImg}/>
                </Badge.Ribbon>  
            } 
       </div>
       <div className={styles.wrapperInfo}>
        <div className={styles.nameWrapper} onClick={() => setIsModalOpen(true)}>{weapon.name}</div>
        <div className={styles.caliberInfo}>Kaliber: {weapon.caliber}</div>
        <div className={styles.buttonWrapper}>
        {
            isAdded ? 
                <>
                    <Tag icon={<CheckCircleOutlined />} className={styles.shoppingBasketTag} color="#085b27" onClick={() => openShoppingBasket()}>Im Paket</Tag> 
                    <span className={styles.deletTagWrapper} onClick={() => removeFromBasket(weapon.id)}><Tag ><DeleteOutlined /></Tag></span>
                    
                </>:
            <Button onClick={() => addToBasket(weapon)} icon={<PlusOutlined />} dark/>
        }
        </div>
        
       </div>
        
       <Media queries={{
          small: " (max-width: 1000px)",
          other: "(min-width: 1001px)"
        }}>
          {matches => (
            
            <Modal
                title={<div className={styles.modalHeaderText}>{weapon.name}</div>}
                centered
                visible={isModalOpen}
                onCancel={e => {
                    e.stopPropagation();
                    onPanelClose()}
                }
                width={matches.small ? "80%" :"40%"}
                className={styles.modal}
                footer={null}
                destroyOnClose                
            >
                <img src={weapon.imgUrl} alt="img" className={styles.weapnImg}/>
                <div className={styles.overlayImg}>{weapon.text}</div>
            </Modal>            
          )}
        </Media>         
       
    </div>);

}

