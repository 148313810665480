import React from "react";
import styles from "./OppeningHours.module.css";

export const OppeningHours: React.FC<{}> = ({}) => {
  return (
    <div className={styles.infoContentWrapper}>
      <table className={styles.openingHoursTable}>
        <tr>
          <td>Montag</td>
          <td>Geschlossen</td>
        </tr>
        <tr>
          <td>Dienstag-Freitag</td>
          <td>
            09:00-12:30
            <br />
            14:00-18:00
          </td>
        </tr>
        <tr>
          <td>Samstag</td>
          <td>09:00-13:00</td>
        </tr>
        <tr>
          <td>Sonntag</td>
          <td>Geschlossen</td>
        </tr>
      </table>
    </div>
  );
};
