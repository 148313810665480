
import React from "react";
import styles from "./ContactComponent.module.css";
import _ from 'lodash';
import { WhatsAppOutlined, PhoneOutlined, MailOutlined, EnvironmentOutlined, PrinterOutlined } from "@ant-design/icons";
import { Popover } from "antd";

export const ContactComponent: React.FC<{}> = ({}) => {

    const onScheißstandClick = () => {
        window?.open("https://goo.gl/maps/Sc5H2P3py5meWXqs7", '_blank')?.focus();
    }

    const onParkhausClick = () => {
        window?.open("https://goo.gl/maps/KHxKw4H8LJSHzzsF9", '_blank')?.focus();
    }

    return (
        <div className={styles.contactWrapper}>
            <div><WhatsAppOutlined /> <a href={"https://wa.me/" + "+49803132559"} target="_blank" className={styles.footerLink}> +49 8031 32559</a></div>            
            <div><PhoneOutlined /><a href="tel:0803132559"> 08031 32559</a> </div>            
            <div><PrinterOutlined /> 08031 139999</div>           
            <div><MailOutlined /><a href="mailto:­waffen@daurer.de"> waffen@daurer.de</a></div><br/>
            <div><EnvironmentOutlined /> Waffen Daurer</div>
            <div>Adlzreiterstraße 4</div>
            <div>83022 Rosenheim</div><br/>                  
            <div>
                Parkplätze bei 
                <Popover content={(<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2251.152103996919!2d12.1276458722411!3d47.85729576260896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47761a47dc0ea473%3A0x572a24881110388d!2sWaffen%20Daurer%20Schie%C3%9Fanlage!5e0!3m2!1sde!2sde!4v1642084834818!5m2!1sde!2sde" width="400" height="300" style={{"border" : "0"}} allowFullScreen={false} loading="lazy"></iframe>)}>
                    <span className={styles.safetyLink} onClick={() => onScheißstandClick()}> Daurer Schießanlage </span>
                </Popover> 
                oder im
                <Popover content={(<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d669.2693027661272!2d12.131301948422996!3d47.857467899534846!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47761a47c0d7e7c9%3A0x3250515deffb7360!2sParkhaus%20P7%20Altstadt%20Ost!5e0!3m2!1sde!2sde!4v1642097871787!5m2!1sde!2sde" width="400" height="300" style={{"border" : "0"}} allowFullScreen={false} loading="lazy"></iframe>)}>
                    <span className={styles.safetyLink} onClick={() => onParkhausClick()}> Parkhaus P7</span>
                </Popover>
                .
            </div><br/>   
            <div>Rosenheim liegt im Süden von Oberbayern, nahe an München und gut über die A8 oder mit der Bahn zu erreichen.</div><br/>    
        </div>    
    );

}
