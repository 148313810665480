import React from "react";
import styles from "./CoursesTmp.module.css";
import _ from 'lodash';
import { Title } from "../../components/Title/Title";
import { ICoursesTmp } from "../../model/ICoursesTmp";
import { Divider, List, Tag, Tooltip } from "antd";
import { Button } from "../../components/Button/Button";
import { AimOutlined, DownloadOutlined } from "@ant-design/icons";

export const CoursesTmp: React.FC<{courses: ICoursesTmp[]}> = ({courses}) => {
        
    const downloadSigneUp = (downloadUrl: string) => {
        const element = document.createElement("a");
        element.href = downloadUrl;
        element.target = "_blank";
        element.download = "/Anmeldung.pdf";
        element.click();
      };

    return (
    <div className={styles.courseWrapper}>  
        <Title text="Lehrgänge" dark/>
        Wir bieten verschiedene Lehrgänge für Sportschützen, Security, Vorderladerschießen und Wiederladen an. Die aktuellsten Termine werden hier aufgelistet, sobald sie feststehen
        <Divider orientation="left" style={{border:'#282828', fontWeight: 550, fontSize: "16px", color: "rgb(51, 51, 51)"}}>Aktuelle Lehrgänge</Divider>    
        <List
        itemLayout="vertical"
        size="large"        
        dataSource={courses}
        className={styles.courseList}
        renderItem={course => (
            <List.Item
                key={course.id}
                actions={[
                
                ]}                
            >
                <List.Item.Meta
                title={<div className={styles.titleWrapper}>{course.name} <div className={styles.downloadButton}><Button onClick={() => downloadSigneUp(course.registrationUrl)} icon={<DownloadOutlined />} text=" Anmeldung" dark/> </div></div>}
                description={course.description}
                style={{marginBottom: 0}}
                />
                Termine:
                {course.dates.length > 0 ?  course.dates.map((date) => 
                    <div><AimOutlined className={styles.iconDate}/>{_.join(date.days, " & ")} {date.completelyBooked &&<Tooltip title="Eintragung auf Warteliste möglich."> <Tag className={styles.completelyBookedTag} color="red">Ausgebucht</Tag></Tooltip>}</div>
                ) :
                <div>- keine vorhanden -</div>}
            </List.Item>
            )}
        />
        
    </div>);

}
