import { ShoppingCartOutlined } from "@ant-design/icons";
import { Badge, Drawer } from "antd";
import { IAppSettings } from "../../../model/IAppSettings";
import { IPackage } from "../../../model/IPackage";
import { IWeapon } from "../../../model/IWeapon";
import { isEvanNumber } from "../../../shared/helper";
import { Button } from "../../Button/Button";
import { Title } from "../../Title/Title";
import { PackageListItem } from "../PackageListItem/PackageListItem";
import { PackageListWeapoItem } from "../PackageListWeapoItem/PackageListWeapoItem";
import styles from "./PackageList.module.css";
import React from "react";

export const PackageList: React.FC<{packages: IPackage[],weapons: IWeapon[]}> = ({weapons, packages}) => {
   

    return ( <>
        <Title text="Fertige Pakete" dark /> 
       <div className={styles.packageListWrapper} >   
           {packages.map((item, index) => (<span key={"pack#" + index}><PackageListItem packageItem={item} evan={isEvanNumber(index)} index={index} weapons={weapons}/></span>))}                  
        </div>
        </>);

}

