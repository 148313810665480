import React from "react";
import { Select } from "antd";
import { createPeopeCosts, IPackage, IPeopeCosts } from "../../../model/IPackage";
import styles from "./PeoplePicker.module.css";

export const PeoplePicker: React.FC<{packageItem: IPackage, value: IPeopeCosts, onChange: (newValue: IPeopeCosts) => void}> = ({packageItem, value, onChange}) => {

    const { Option } = Select;

    const options = packageItem?.peopleCosts.map((peoCost, index) => (<Option value={peoCost.count}>{peoCost.count}</Option>));

    const onSelectChnage = (newValue: number) => {
        var newObj =  packageItem?.peopleCosts.find(p => p.count == newValue) ?? createPeopeCosts();
        onChange(newObj);
    }

    return (<div className={styles.peoplepickerwrapper}>
        <span className={styles.label}>Personen:</span>
<Select  style={{ width: 75 }} defaultValue={1} onChange={(value) => onSelectChnage(value)}>
            {options}
        </Select>
    </div>
        
    );

}
