import { Divider } from "antd";
import React from "react";
import { IWeapon } from "../../../model/IWeapon";
import styles from "./WeaponListItem.module.css";

export const WeaponListItem: React.FC<{weapon: IWeapon}> = ({weapon}) => {
     
    const imgComponent = (
        <div className={styles.imgWrapper}>
            
            <img src={weapon.imgUrl} alt="img" className={styles.weapnImg}/>
            
         </div> 
    );

    const infoComponnt = (
        <div className={styles.infoWrapper}>
            <Divider orientation="left" className={styles.itemTitle} style={{border:'#333'}}>
                {weapon.name}
            </Divider>            
            <div className={styles.weaponText}>{weapon.text}</div>
                 
        </div>    
    );
    
    return (
        <div className={styles.itemWrapper}>  
            <>
            {imgComponent}
            {infoComponnt}
            </>       
    </div>);

}
