

export interface IHistory{    
    year: string,
    text: string,
    imgUrl?: string
}

export function creatHistroy(options?: Partial<IHistory>):IHistory {
    return Object.assign(
        {
            year: "",
            text: "",
            imgUrl: ""
        },options
    );
}