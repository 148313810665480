import classNames from "classnames";
import React from "react";
import styles from "./Button.module.css";

export const Button: React.FC<IButtonProps> = ({onClick, text, icon, dark, big, idStr, disabled,filled}) => {
    
    var typeString =(disabled ? "Disabled": (dark ?  "Dark" : "Light") + (filled ? "Filled" : "")) 
    const wrapperStyle = "buttonWrapper" + typeString;

    const onClickEvent = ()=> {
        if(!disabled) {
            onClick()
        }
    }

    return (
        <div className={classNames( styles[wrapperStyle], big ? styles.buttonWrapperBig : undefined)} id={idStr ?? ""} onClick={() => onClickEvent()}>
            <div>
                {icon} {text?.toUpperCase()}
            </div>
        </div>);
}

interface IButtonProps{
    onClick: () => void;
    text?: string;   
    icon?: JSX.Element;
    dark?: boolean; 
    big?: boolean;
    filled?: boolean;
    idStr?: string;
    disabled?: boolean;
}