import React, { useContext } from "react";
import styles from "./EventAd.module.css";
import { Button } from "../Button/Button";
import { SettingsContext } from "../../App";

export const EventAd: React.FC<{}> = ({}) => {
    const appsettings = useContext(SettingsContext)

    return (
    <div className={styles.coursesListItemWrapper}>     
        <div className={styles.itemImg} style={{background: `linear-gradient( rgb(0 0 0 / 26%), rgb(0 0 0 / 75%) ),url(${appsettings.eventAd.imgUrl}) center center / cover`}}>
            <div className={styles.itemContent}>
                <div>{appsettings.eventAd.name}</div>
                <div>{appsettings.eventAd.description}</div>  
                <Button onClick={() =>window.location.href = appsettings.eventAd.link} text="Mehr Infos" dark={false}/> 
            </div>    
                     
        </div>
        
        
    </div>);

}
