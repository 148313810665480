export const formatToEuro = (euroNumber: number):string => {    
    const formatter = Intl.NumberFormat("de-DE", {style: 'currency', currency: 'EUR', minimumFractionDigits: 0});
    return formatter.format(euroNumber);

};

export const formatTime = (minutesNuber: number):string => {    
    const hours = Math.floor(minutesNuber / 60) ;
    const minutes = minutesNuber - (hours * 60);

    const hourStr = `${hours} Stunde${hours > 1 ? "n" : ""}`;
    const minuteStr = `${minutes} Minute${minutes > 1 ? "n" : ""}`;

    if(hours == 0){
        return minuteStr;
    }
    else if(minutes == 0){
        return hourStr;
    }
    return `${hourStr} ${minuteStr}`;    

};

const weekDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]



export const formatDatePickerDate = (date: Date) => {

    const weekDay = weekDays[date.getDay()];
    return `${weekDay} ${date.toLocaleDateString()}`;

}