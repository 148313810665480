import { claculateTotalPrice, claculateTotalWeapons, claculateTotalwithouDiscount, getDuration, getMunitonCount } from "../shared/individuellPackageCalculator";
import { IWeapon } from "./IWeapon";

export interface IIndividualPricing{
    basePrice: number;
    weaponCategroies: ICategorieItem[];
    peopleCountSpecs: IPeopleCountSpecs[];
}

export interface ICategorieItem{
    id: number;
    price: number;
}

export interface IPeopleCountSpecs{
    count: number;
    discountFraction: number;
    munitonCount: IUnderOver5Props;
    duration: IUnderOver5Props;
}

export interface IUnderOver5Props{
    under5: number;
    greaterOrEqual5: number;
}

export interface ICalculatedPrice{
    basPrice: number
    weaponsPrice: number,
    subtotal: number
    groupDiscount: number,
    totalPP: number,
    total: number,
    munition: number,
    duration: number
}

export function createCalculatedPrice (indiviualPricing: IIndividualPricing, weapons: IWeapon[], peopleCount: number): ICalculatedPrice {
    
    return Object.assign(
        {
            basPrice: indiviualPricing.basePrice,
            weaponsPrice: claculateTotalWeapons(indiviualPricing, weapons),
            subtotal: claculateTotalwithouDiscount(indiviualPricing, weapons),
            groupDiscount: claculateTotalwithouDiscount(indiviualPricing, weapons) -claculateTotalPrice(indiviualPricing, weapons,peopleCount),
            totalPP: claculateTotalPrice(indiviualPricing, weapons,peopleCount),
            total: claculateTotalPrice(indiviualPricing, weapons,peopleCount)*peopleCount,
            munition: getMunitonCount(indiviualPricing, weapons,peopleCount),
            duration: getDuration(indiviualPricing, weapons,peopleCount),
        }
    );
}