import React, { useContext, useEffect, useState } from "react";
import styles from "./Navigation.module.css";
import { IAppSettings } from "../../model/IAppSettings";
import { useNavigate } from "react-router-dom";
import Media from "react-media";
import { MenuOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Menu, notification } from "antd";
import classNames from "classnames";
import { routes } from "../../routes";
import { SettingsContext } from "../../App";
import { trackPage } from "../../GATools";

export const Navigation: React.FC<INavigationProps> = ({ setting, scrollPositionTop, setNavigationVisible, isLoggedIn, toggleUserModal }) => {
  const appsettings = useContext(SettingsContext);

  const [menuVisible, setMenuVisible] = useState(false);

  let navigate = useNavigate();

  const onMenuClick = (url: string, setNavVisible: boolean, refesh?: boolean) => {
    setNavigationVisible(setNavVisible);
    toggleUserModal(false);
    navigate(url, { replace: true });
    if (refesh) window.location.reload();
    trackPage();
    setMenuVisible(false);
    if (window.location.pathname != routes.home) {
      notification.close("additional");
      notification.close("basic");
    }
  };

  const getClassName = (route: string): string => {
    return route == window.location.pathname || ((window.location.pathname + "/").startsWith(route) && route.length > 1) ? styles.navItemSelected : styles.navItem;
  };

  const getClassNameSmall = (route: string): string => {
    return route == window.location.pathname ? styles.menuItemWrapperSelected : styles.menuItemWrapper;
  };

  const shootingRangeSubMenu = (
    <Menu className={styles.hoverSubMenu} style={{ backgroundColor: !scrollPositionTop || menuVisible ? "#333" : "#333333b3" }}>
      <Menu.Item className={styles.hoverSubMenuItem}>
        <div onClick={() => onMenuClick(routes.shootingRange100, false)}>100 Meter</div>
      </Menu.Item>
      <Menu.Item className={styles.hoverSubMenuItem}>
        <div onClick={() => onMenuClick(routes.shootingRange25, false)}>25 Meter</div>
      </Menu.Item>
    </Menu>
  );
  const eventSubMenu = (
    <Menu className={styles.hoverSubMenu} style={{ backgroundColor: !scrollPositionTop || menuVisible ? "#333" : "#333333b3" }}>
      <Menu.Item className={styles.hoverSubMenuItem} onClick={() => onMenuClick(routes.eventPackages, false)}>
        Pakete
      </Menu.Item>
      <Menu.Item className={styles.hoverSubMenuItem} onClick={() => onMenuClick(routes.eventIndividuall, false)}>
        Individuell
      </Menu.Item>
      <Menu.Item className={styles.hoverSubMenuItem} onClick={() => onMenuClick(routes.eventQundA, false)}>
        Q&A
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={classNames(styles.navigationWrapper)} style={{ backgroundColor: !scrollPositionTop || menuVisible ? "#333" : "#333333b3" }}>
      <a href={setting.urls.mainPageURL} target="_self">
        <div style={{ backgroundImage: `url(https://daurer.de/images/event/logo_event.png)` }} className={styles.logo} />
      </a>
      <Media
        queries={{
          small: " (max-width: 999px)",
          large: "(min-width: 1000px)",
        }}
      >
        {(matches) => (
          <>
            {matches.large && (
              <div className={styles.navButtonWrapper}>
                <div onClick={() => onMenuClick(routes.home, true)} className={getClassName(routes.home)}>
                  Home
                </div>
                <Dropdown placement="bottomCenter" overlay={eventSubMenu}>
                  <div onClick={() => onMenuClick(routes.event, true)} className={getClassName(routes.event)}>
                    Schießtrainings
                  </div>
                </Dropdown>
                <div onClick={() => onMenuClick(routes.courses, false)} className={getClassName(routes.courses)}>
                  Lehrgänge
                </div>
                <Dropdown placement="bottomCenter" overlay={shootingRangeSubMenu}>
                  <div onClick={() => onMenuClick(routes.shootingRange, false)} className={getClassName(routes.shootingRange)}>
                    Schießstand
                  </div>
                </Dropdown>
              </div>
            )}
            {matches.small && (
              <div className={styles.smallMenuButtonWrapper}>
                <MenuOutlined
                  className={styles.smallMenuIcon}
                  style={{ transform: menuVisible ? "skew(-16deg)" : "skew(0deg)" }}
                  onClick={() => {
                    setMenuVisible(!menuVisible);
                  }}
                />

                <div className={styles.smallMenuWrapper} style={{ width: menuVisible ? "100vw" : "0vw" }}>
                  <div className={getClassNameSmall(routes.home)} onClick={() => onMenuClick("/", true)}>
                    {"Home"}
                  </div>
                  <Divider className={styles.smallMenuDivider} />
                  <div className={getClassNameSmall(routes.event)} onClick={() => onMenuClick(routes.event, false)}>
                    {"Schießtrainings"}
                  </div>
                  <Divider className={styles.smallMenuDivider} />
                  <div className={styles.submenuItemWrapper} onClick={() => onMenuClick(routes.eventPackages, false)}>
                    {"Pakete"}
                  </div>
                  <Divider className={styles.smallMenuDivider} />
                  <div className={styles.submenuItemWrapper} onClick={() => onMenuClick(routes.eventQundA, false)}>
                    {"Q&A"}
                  </div>
                  <Divider className={styles.smallMenuDivider} />
                  <div className={getClassNameSmall(routes.courses)} onClick={() => onMenuClick(routes.courses, false)}>
                    {"Lehrgänge"}
                  </div>
                  <Divider className={styles.smallMenuDivider} />
                  <div className={getClassNameSmall(routes.shootingRange)} onClick={() => onMenuClick(routes.shootingRange, false)}>
                    {"Schießstand"}
                  </div>
                  <Divider className={styles.smallMenuDivider} />
                  <div className={styles.submenuItemWrapper} onClick={() => onMenuClick(routes.shootingRange100, false)}>
                    {"100 Meter"}
                  </div>
                  <Divider className={styles.smallMenuDivider} />
                  <div className={styles.submenuItemWrapper} onClick={() => onMenuClick(routes.shootingRange25, false)}>
                    {"25 Meter"}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Media>
    </div>
  );
};

interface INavigationProps {
  setting: IAppSettings;
  scrollPositionTop: boolean;
  setNavigationVisible: (newState: boolean) => void;
  isLoggedIn: boolean;
  toggleUserModal: (newState: boolean) => void;
}
