import React from "react";
import styles from "./Introduction.module.css";
import { Title } from "../../../components/Title/Title";

export const Introduction: React.FC<{introductionTextLocalize?: string,introductionText?: string }> = ({introductionTextLocalize, introductionText}) => {

    return (
        <div className={styles.introductionWrapper}>
            
            <Title text="Du willst mit einer echten Waffe schießen?" dark/>
            {
                !introductionText ? (
                    <div>Dann buche jetzt Dein Schnupperschießen und teste aus sportlichem Blickwinkel, wie gut Du triffst.<br/>Unser freies Schießen bietet alles von Kleinkaliber bis zu Gewehren, Revolver und Pistolen in großen Kalibern.<br/>Das Schießtraining ist für Jedermann und -frau ab 18 Jahren.<br/>{introductionTextLocalize}</div>
                ):
                (
                    <div>{introductionText}</div>
                )
            }
             
           
        </div> );

}
