import './App.less';
import { AppComponent } from './AppComponent';
import React from 'react';
import { IAppSettings } from './model/IAppSettings';
import { IHistory } from './model/IHistory';
import { ITeam } from './model/ITeam';
import { ICoursesTmp } from './model/ICoursesTmp';
import { IShootingRange } from './model/IShootingRange';
import { IAd } from './model/IAd';
import { IIndividualPricing } from './model/IIndividualPricing';
import { IPackage } from './model/IPackage';
import { IWeapon } from './model/IWeapon';
import { IQuestionAndAwnser } from './model/IQuestionAndAwnser';
import { IReview } from './model/IReview';
import { initalizieGA } from './GATools';

export const SettingsContext = React.createContext<IAppSettings>({} as IAppSettings);
export const IndiviualPricingContext = React.createContext<IIndividualPricing>({} as IIndividualPricing);

export class App extends React.Component<IAppProps, IAppState>{

  constructor(props: IAppProps){
    super(props);
  }
  
  
  public render (){

    initalizieGA(this.props.appSettings.eventSettings.gaTrackingID)

    return (
      <div className="App"> 
        <SettingsContext.Provider value={this.props.appSettings}>
          <IndiviualPricingContext.Provider value={this.props.individualPricing}>
            <AppComponent {...this.props}/>   
          </IndiviualPricingContext.Provider>  
        </SettingsContext.Provider>
    </div>
  );
  }
  
}

export interface IAppProps{
  
  appSettings: IAppSettings
  history: IHistory[]
  team: ITeam[],
  courses: ICoursesTmp[],
  shootingRanges: IShootingRange[],
  ads: IAd[],
  weapons: IWeapon[],
  packages: IPackage[],
  questionsAndAwnsers: IQuestionAndAwnser[],
  reviews: IReview[],
  individualPricing: IIndividualPricing
}

export interface IAppState{
  
}
