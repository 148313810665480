import React from "react";
import styles from "./Banner.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";

export const Banner: React.FC<{scrollPositionTop: boolean, setNavigationVisible: (newState: boolean) => void, title: string, subTitle: string, buttonLink?: string}> = ({scrollPositionTop, setNavigationVisible, title, subTitle, buttonLink}) => {

    
    return (
    <div className={classNames(styles.bannerWrapper, scrollPositionTop ? styles.bannerWrapperFullHeight : styles.bannerWrapperSetHeight)} style={{height: !scrollPositionTop ? "95vh" : "100vh"}}>  
        <div className={styles.infoWrapper}>    
            <div className={styles.infoTitel}>{(title).toUpperCase()}</div>  
            <div className={styles.infoLowerTitel}>{(subTitle)}</div>    
            {buttonLink != undefined ? <div className={styles.bannerButtonWrapper}> <Link to={{ pathname: buttonLink }}  onClick={() => setNavigationVisible(false)}><Button  onClick={() => {}} text="Lehrgänge" dark={false} big/> </Link></div> : <></>}   
        </div>       
       <div style={{background: `url(https://daurer.de/images/HomeBanner.png) center center / cover`, height: !scrollPositionTop ? "95vh" : "100vh"}} className={styles.picture}></div>                          
    </div>);

}
