

import emailjs from 'emailjs-com';
import { IAppSettings } from '../model/IAppSettings';
import { message } from 'antd';
import { ICalculatedPrice } from '../model/IIndividualPricing';
import { formatTime, formatToEuro } from './formatter';
import { IEventBooking, IEventBookingIndividuell } from '../model/IEventBooking';

export const  sendEmail = async (appSettings: IAppSettings, values: IEventBooking) => {
    const params = {
        eventName: values.packageName,
        eventId: values.packageId,
        name: values.name,
        email: values.email,
        phone: values.phone,
        comment: values.comment,
        timestamp: values.timestamp,
        peopelCount: values.peopelCount,
        desiredDate: values.desiredDate,
        picketDate: values.picketDate
    }
    send(appSettings, params, appSettings.eventSettings.emailJsSettings.templateId);
}

export const  sendEmailIndividuall = async (appSettings: IAppSettings, values: IEventBookingIndividuell, calculatedPrice: ICalculatedPrice) => {
    const params = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        comment: values.comment,
        timestamp: values.timestamp,
        peopelCount: values.peopelCount,
        weapons: values.weapons.join("<br>"),
        desiredDate: values.desiredDate,
        picketDate: values.picketDate,
        basePrice: formatToEuro(calculatedPrice.basPrice),
        weaponsPrice: formatToEuro(calculatedPrice.weaponsPrice),
        subtotal: formatToEuro(calculatedPrice.subtotal),
        groupDiscount: formatToEuro(calculatedPrice.groupDiscount),
        totalPP: formatToEuro(calculatedPrice.totalPP),
        total: formatToEuro(calculatedPrice.total),
        duration: formatTime(calculatedPrice.duration),
        munition: calculatedPrice.munition
    }
    
    send(appSettings, params, appSettings.eventSettings.emailJsSettings.templateIdIndividuall);
 
}


const send =async (appSettings: IAppSettings, params:any, templateId: string) => {
    emailjs.send(appSettings.eventSettings.emailJsSettings.serviceId, templateId, params, appSettings.eventSettings.emailJsSettings.userId)
    .then((result) => {          
      message.success('Anfrage gesendet');
      return true;
    }, (error) => {
        console.log(error.text);
        message.error('Anfrage fehlgeschlagen');
        return false
    });
}

