
import React from "react";
import styles from "./ServiceItem.module.css";
import _ from 'lodash';
import { AimOutlined } from "@ant-design/icons";

export const ServiceItem: React.FC<{title: string, text: string | React.ReactNode}> = ({title, text}) => {

    return (   
        <div>
            <div className={styles.generalInfoTitle}>
                <AimOutlined className={styles.icon} />{(" " + title).toUpperCase()}
            </div>
            <div className={styles.generalInfoText}>
                {text}
            </div>
        </div>);

}
