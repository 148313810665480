import { Modal } from "antd";
import React, { useState } from "react";
import { ITeam } from "../../model/ITeam";
import styles from "./TeamItem.module.css";
import { UserOutlined } from "@ant-design/icons";

export const TeamItem: React.FC<{teamMember: ITeam}> = ({teamMember}) => {

    const [detailsOpen, setDetailsOpen] = useState(false);

    return (
        <>
                
        <div className={styles.teamItemWrapper}>   
            <div className={styles.teamItemWrapperInner} onClick={() => setDetailsOpen(true)}>
                <div className={styles.memberImgWrapper}>  
                    {
                        teamMember.imgUrl !="" ? 
                        <img className={styles.memberImg} src={teamMember.imgUrl}/> :
                        <UserOutlined className={styles.memberMissingImg}  />
                    }                  
                    
                </div>
                <div className={styles.memberTextWrapper}>
                    <div>{teamMember.name}</div>
                    <div>{teamMember.descirption}</div>
                </div>   
            </div> 
                        
        </div>
    </>);

}
