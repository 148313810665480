import React from "react";
import styles from "./Footer.module.css";
import { BankOutlined, CreditCardOutlined, EnvironmentOutlined, EuroCircleOutlined, EuroOutlined, FacebookOutlined, InstagramOutlined, MailOutlined, PhoneOutlined, PrinterOutlined, SolutionOutlined, TagOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { IAppSettings } from "../../model/IAppSettings";
import { routes } from "../../routes";

export const Footer: React.FC<{setting: IAppSettings, setCookieBannerOpen : (val: boolean) => void}> = ({setting, setCookieBannerOpen}) => {

    return (
    <div className={styles.footerWrapper}>
        <div className={styles.footerContentWrapper}>           
            <div className={styles.footerMenuWrapper}>
                <div className={styles.footerPartTitle}>{("Menü").toUpperCase()}</div>
                <a href={routes.home} className={styles.footerLink}>Home</a><br/>
                <a href={routes.event} className={styles.footerLink}>Events</a><br/>
                <a href={routes.courses} className={styles.footerLink}>Lehrgänge</a><br/>
                <a href={routes.shootingRange} className={styles.footerLink}>Schießstand</a><br/>
                {/*<a href={routes.account} className={styles.footerLink}>Konto</a><br/>*/}
            </div>
            <div className={styles.footerLegalWrapper}>
                <div className={styles.footerPartTitle}>{("Service").toUpperCase()}</div>
                <a href={routes.imprint} className={styles.footerLink}>Impressum</a><br/>
                <a href={routes.privacyStatement} className={styles.footerLink}>Datenschutzerklärung</a><br/>
                <a href="javascript:void(null)" role="button" className={styles.footerLink} onClick={() => setCookieBannerOpen(true)}>Cookie Einstellungen</a><br/><br/>
            </div>
            <div className={styles.footerContactWrapper}>
                <div><div className={styles.footerPartTitle}>{("Kontakt").toUpperCase()}</div>
                <div><WhatsAppOutlined /> <a href={"https://wa.me/" + "+49803132559"} target="_blank" className={styles.footerLink}> +49 8031 32559</a></div>                
                <div><PhoneOutlined /><a href="tel:0803132559" className={styles.adressInfoPhone}> 08031 32559</a> </div> 
                <div><PrinterOutlined /> 08031 139999</div>                   
                <div><MailOutlined /><a href="mailto:­waffen@daurer.de"> waffen@daurer.de</a></div><br/>
               
                </div>
                <div>
                    <div><EnvironmentOutlined /> Waffen Daurer</div>
                    <div className={styles.adressInfo}>Adlzreiterstraße 4</div>
                    <div className={styles.adressInfo}>83022 Rosenheim</div><br/>  
                </div>            
            </div>        
            <div className={styles.footerCopyright}>Copyright © {(new Date()).getFullYear()} all rights reserved by Waffen Daurer</div>
            <div className={styles.footerScoialWrapper}> 
                <a href={"https://wa.me/" + "+49803132559"} target="_blank" className={styles.footerLink}><WhatsAppOutlined className={styles.icon}/>  </a>
                <a href={setting.urls.instaUrl} target="_blank" className={styles.footerLink}><InstagramOutlined className={styles.icon}/>  </a>
                <a href={setting.urls.facebookUrl} target="_blank" className={styles.footerLink}><FacebookOutlined className={styles.icon}/>  </a>
            </div>          
        </div>
    </div>);

}
