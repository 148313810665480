import React from "react";
import styles from "./GeneralInfoComponent.module.css";
import _ from 'lodash';
import { Title } from "../Title/Title";
import { GeneralInfoItem } from "../GeneralInfoItem/GeneralInfoItem";

export const GeneralInfoComponent: React.FC<{}> = ({}) => {

    const onSafetyClick = () => {
        var elmnt = document.getElementById("safety");
        elmnt?.scrollIntoView();
    }

    return (   
        <div className={styles.infoWrapper}>
            <Title text="Paketinhalt" white />
            <div className={styles.generalInfoWrapper}>            
                <GeneralInfoItem title="Leihwaffen" text="Alle gebuchten Waffen werden bereitgestellt und sind mit verschiedenen Zielfernrohren, Red Dots oder weiterem Zubehör ausgestattet."/>
                <GeneralInfoItem title="Munition" text="Die passenden Munition zu den Leihwaffen kannst Du in der angegebenen Menge verschießen. Wenn Du mehr als die enthaltene Munition verschießen willst, kannst Du zusätzlich günstig Munition kaufen."/>
                <GeneralInfoItem title="Schießaufsicht" text="Eine geschulte und fachkundige Aufsichtsperson ist das gesamte Event für die Schießeinweisung, Sicherheit und Informationen zu den Waffen mit Dir auf dem Schießstand."/>
                <GeneralInfoItem title="Schutzausrüstung" text={<div>Alle teilnehmenden Personen erhalten die notwendige Schutzsausrüstung wie Gehörschützer und Schutzbrille. <br/><span className={styles.safetyLink} onClick={() => onSafetyClick()}>Siehe Sicherheitshinweise</span></div>}/>   
                <GeneralInfoItem title="Schießstandmiete" text="Unser Schießstand mitten in Rosenheim wird für Dein Event reserviert."/>          
            </div>  
    </div>);

}
