import React from "react";
import styles from "./Imprint.module.css";
import _ from "lodash";
import { Title } from "../../components/Title/Title";

export const Imprint: React.FC<{}> = ({}) => {
  return (
    <div className={styles.imprintWrapper}>
      <Title text="Impressum" dark />
      <table className={styles.imprintTable}>
        <tr>
          <td className={styles.imrpitnCompanyName}>Waffen Daurer</td>
        </tr>
        <br />
        <tr>
          <td>Inhaber</td>
          <td>Thomas Daurer</td>
        </tr>
        <tr>
          <td>Adresse</td>
          <td>
            Adlzreiterstr. 4<br />
            83022 Rosenheim
          </td>
        </tr>
        <br />
        <tr>
          <td>Email</td>
          <td>waffen@daurer.de</td>
        </tr>
        <tr>
          <td>Telefon</td>
          <td>08031 32559</td>
        </tr>
        <tr>
          <td>Fax</td>
          <td>08031 13999</td>
        </tr>
        <br />
        <tr>
          <td>USt-Id.Nr.</td>
          <td>DE 338 460 019</td>
        </tr>
        <tr>
          <td>Steuer-Nr.</td>
          <td>156 210 33201</td>
        </tr>
        <tr>
          <td>Konto-Nr.</td>
          <td>DE47 7115 0000 0000 016527</td>
        </tr>
        <br />
        <tr>
          <td>NWR-ID</td>
        </tr>
        <tr>
          <td>Person</td>
          <td>F2012-11-21-0000078-V</td>
        </tr>
        <tr>
          <td>Hersteller</td>
          <td>E2021-05-06-0000561-M</td>
        </tr>
        <tr>
          <td>Händler</td>
          <td>E2012-11-21-0035851-I</td>
        </tr>
      </table>
    </div>
  );
};
