export interface IWeapon {
    id: number
    name: string,
    text: string
    imgUrl: string,
    caliber: string,
    priceCategorie: number
}
export function createWeapon (options?: Partial<IWeapon>): IWeapon {
    return Object.assign(
        {            
            id: -1,
            name: "",
            text: "",
            imgUrl: "",
            caliber: "",
            priceCategorie: -1
        },options
    );
}

export interface IWeaponRef {
    id: number
    shots: number,
}

export function createWeaponRef (options?: Partial<IWeaponRef>): IWeaponRef {
    return Object.assign(
        {
            id: 0,
            shots: 0,
        },options
    );
}