import { AimOutlined } from "@ant-design/icons";
import { Carousel, Divider } from "antd";
import classNames from "classnames";
import React, { CSSProperties, useState } from "react";
import { getPeopleCosts, IPackage } from "../../../model/IPackage";
import { Link, useNavigate } from "react-router-dom";
import { IWeapon } from "../../../model/IWeapon";
import { formatTime, formatToEuro } from "../../../shared/formatter";
import { Button } from "../../Button/Button";
import styles from "./PackageListItem.module.css";
import ImageCarousel from "../../ImageCarousel/ImageCarousel";

export const PackageListItem: React.FC<{packageItem: IPackage, index: number, evan?: boolean, stacked?: boolean, weapons: IWeapon[], onHomeSelect?: (packageRef: IPackage)=> void}> = ({packageItem, evan, stacked = false, index : packageindex, weapons, onHomeSelect }) => {
  

    const onToPackageClick = (packageItem: IPackage) => {        
        window.scrollTo({top: 0, behavior: 'auto'});
        onHomeSelect && onHomeSelect(packageItem);
    }

    const getWeaponById = (id: number) => {
        const index = weapons?.findIndex(weapon => weapon.id == id);
        if(index != -1  && index != undefined){
            return weapons[index];
        }
        return null;
    }

    let navigate = useNavigate();
    const onImageClick= (target: any) => {
        if(target.nodeName == "IMG" ){
            navigate(`/event/pakete/${packageItem.title}?id=${packageItem.id}`, {replace: false});
            window.scrollTo(0,0 );
        }        
    }

    const getPricesString = ():string => {
        var len = packageItem.peopleCosts.length  
        if (len > 1 ){
            return formatToEuro(packageItem.peopleCosts[len-1].price) + " - " + formatToEuro(packageItem.peopleCosts[0].price) 
        } 
        return formatToEuro(packageItem.peopleCosts[0].price) 
    }

    const includesWeapons = weapons.filter(weapon => packageItem?.weapons.findIndex(weaponRef => weaponRef.id == weapon.id) != -1);

    const imgComponent = (
        <div className={styles.imgWrapper} style={stacked ? {maxWidth: "100%"} : {}} onClick={(e)=> onImageClick(e.target)}>
            <ImageCarousel packageItem={packageItem} index={packageindex} selectedImg={-1} onImgChange={(index) => {}} includesWeapons={includesWeapons}/>            
         </div> 
    );


    const infoComponnt = (
        <div className={styles.infoWrapper} style={stacked ? {height: "58%"} : {}}>
            <Divider orientation="left" className={styles.itemTitle} style={{border:'#333'}} >
                {packageItem.title}
            </Divider>            
            <div className={styles.itemShortInfo}>{packageItem.shortInfo}</div>
            <div className={styles.itemDuration}></div>
            {packageItem.weapons.map((weaponRef, index) => 
                {const weapon = getWeaponById(weaponRef.id);
                    return (<div className={styles.infoItem} key={`weapon#${index}.${index}`}>
                        <AimOutlined className={styles.icon} />
                        <div className={styles.textSpan}> {weapon?.name}</div>
                    </div>)  }              
            )}
             <div className={styles.priceButtonWrapper }> 
                <div className={styles.priceButtonWrapperInner}>               
                    <div className={styles.itemPrice}>{getPricesString()}</div>                      
                    <div className={styles.itemButton}> <Link to= {{pathname: `/event/pakete/${packageItem.title}?id=${packageItem.id}`}} onClick={() => onToPackageClick(packageItem)}><Button onClick={() => {}} text="Mehr dazu" dark/></Link> </div>   
                    <div className={styles.itemPerPerson}>
                        <span>pro Person (Personenzahlabhängig)</span>
                    </div> 
                </div>          
            </div>     
        </div>    
    );

    return (
    <div className={classNames(styles.itemWrapper, evan || stacked ? styles.itemWrapperEvan : styles.itemWrapperUnevan, stacked ? styles.itemWrapperStacked : "" )}>  
        <>
        {imgComponent}
        {infoComponnt}
        </>                  
    </div>);

}
