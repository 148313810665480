import { AimOutlined } from "@ant-design/icons";
import classNames from "classnames";
import React from "react";
import styles from "./SafetyItem.module.css";

export const SafetyItem: React.FC<{text: string, dark? :boolean }> = ({text, dark = false, children}) => {

    return (        
        <div className={classNames(styles.infoItem, dark ? styles.infoDark : undefined)} >
            <AimOutlined className={styles.icon} />
            <div className={styles.textSpan}>{ " "+ text}{children}</div>
        </div>);

}
