import { Carousel, Divider } from "antd";
import React from "react";
import { IReview } from "../../model/IReview";
import { Title } from "../Title/Title";
import styles from "./Review.module.css";

export const Review: React.FC<{ reviews: IReview[]}> = ({reviews}) => {

    return (
        <div className={styles.reviewWrapper} >     
       <Title text="REVIEWS"/>
        <Carousel autoplay autoplaySpeed={8000} style={{ width: "80%", maxWidth: "66em", position: "relative", margin: "0 auto"}}>
                {reviews?.map((item, index) => (
                <div className={styles.reviewItemWrapper} key={"review#" + index}>
                    <Divider orientation="left" className={styles.divider} style={{borderColor:'#333', color: "#747474"}}>{item.person}</Divider>            
                    <div className={styles.reviewText}>{item.text}</div>
                </div>))}       
            </Carousel>            
    </div>);

}
