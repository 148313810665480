import { AimOutlined, DeleteOutlined, StarFilled } from "@ant-design/icons";
import { Badge, Checkbox, Divider, Form, Input, List, Modal, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { IAppSettings } from "../../../model/IAppSettings";
import { Button } from "../../Button/Button";
import styles from "./BookingModalIndividuall.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { sendEmailIndividuall } from "../../../shared/emailService";
import Media from "react-media";
import { IWeapon } from "../../../model/IWeapon";
import { formatDatePickerDate, formatToEuro } from "../../../shared/formatter";
import { useNavigate } from "react-router-dom";
import { IndiviualPricingContext } from "../../../App";
import { createCalculatedPrice } from "../../../model/IIndividualPricing";
import { IEventBookingIndividuell, createEventBookingIndividuell } from "../../../model/IEventBooking";

export const BookingModalIndividuall: React.FC<{visible: boolean, onCancel: () => void, weapons: IWeapon[], settings: IAppSettings, removeWeapon: (id: number) => void; clearBasked: () => void, setPeopelCount: (count: number) => void}> = ({visible, onCancel, weapons, settings, removeWeapon, clearBasked, setPeopelCount}) => {
     
    const { TextArea } = Input;    
    const { Option } = Select;
    const [form] = Form.useForm();

    
    var peopleCountURL = Number((new URLSearchParams(window.location.search)).get("pp") ?? "1")
    const indiviualPricing = useContext(IndiviualPricingContext);
    const calculatedPrice =  createCalculatedPrice(indiviualPricing, weapons, peopleCountURL);

    const dateOptions = settings.eventSettings.possibleBookingDays.map((date) => new Date(date)).filter((date) => date > new Date()).map((date, index) => (<Option value={formatDatePickerDate(date)}>{formatDatePickerDate(date)}</Option>));
    const options = indiviualPricing.peopleCountSpecs.map((peoCount, index) => (<Option value={peoCount.count}>{peoCount.count}</Option>));

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [reCaptchaValue, setReCaptchaValue] = useState(null as string | null);
    const [step, setStep] = useState(0);

    
    const checkIsValid = () => {
        const fields = form.getFieldsValue(true);   
        const emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);    
        setIsValid(fields.name != "" &&
        emailRegEx.test(fields.email) &&
        fields.phone != "" &&
        fields.peopelCount > 0 &&
        fields.privacyAccepted);              
    }

    const onPanelClose = () => {
        form.resetFields()
        setStep(0)
        onCancel();
    }

    const onSendRequest = (booking: IEventBookingIndividuell) => {
        if(isValid && !buttonDisabled && reCaptchaValue!= null){
            booking.timestamp = new Date().toLocaleString();
            booking.weapons = weapons.map((we, index) => `<div>${index + 1}. ${we.name}<br></div>`)    
            sendEmailIndividuall(settings, booking, calculatedPrice);
            onPanelClose();
            clearBasked();
        }
        
    }
    
    const getRibbonColor = (weapon: IWeapon) :string[]=> {
        switch (weapon.priceCategorie){
            case 0:
                return [styles.ribbonBronze,"#7a3818"];
                break;
            case 1:
                return [styles.ribbonSilver,"#444444" ];
                break;
            case 2 : 
                return [styles.ribbonGold, "#3a2c0f"];
                break;
        } 
        return [styles.ribbonBronze,"#7a3818"];
    }


    const selectedWeaponsList = (
        <span className={styles.weaponsWrapper}>
            <Divider orientation="left" className={styles.weaponTitle} style={{border:'#333'}} >Ausgewählte Waffen</Divider>    
            <Media queries={{
                small: " (max-width: 680px)",
                large: " (min-width: 681px)"
                }}>
                {matchesCapther => (
                    <List
                    itemLayout={matchesCapther.small ? "vertical" : "horizontal"}
                    dataSource={weapons}
                    renderItem={(item: IWeapon) => {
    
                        const color = getRibbonColor(item) as string[];
                        return (
                        <List.Item
                        actions={[<div className={styles.weaponPriceWrapper}>{formatToEuro(indiviualPricing.weaponCategroies[item.priceCategorie].price)}</div>,<DeleteOutlined className={styles.deleteIcon} style={weapons.length <= 3 ? {display: "none"}: {}} onClick={() => removeWeapon(item.id)} />]}
                        >
                        <List.Item.Meta
                            avatar={
                                <Badge.Ribbon text={<StarFilled className={styles.ribbonPremiumStar} /> } className={color[0]} color={color[1]}>
                                    <img src={item.imgUrl} alt="img" className={styles.weapnImg}/>
                                </Badge.Ribbon>  }
                            title={item.name}
                            description={`Kaliber: ${item.caliber}`}
                            />
                        </List.Item>
                    )}}
                    />)}                                       
        </Media>                       
            
        </span>
    )

    
    const inputForm = (
        <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={createEventBookingIndividuell({peopelCount: Number((new URLSearchParams(window.location.search)).get("pp") ?? "1") })}
            onFinish={(values) =>{checkIsValid(); onSendRequest(values)}}
            autoComplete="off"
            onFieldsChange={() => checkIsValid()}
            className={styles.modalForm}                                   
            >
                <Divider orientation="left" className={styles.weaponTitle} style={{border:'#333'}} >Buchungsinformationen</Divider>
            <Form.Item label="Vollständiger Name" name="name" rules={[{ required: true,  message: ""  }]}>
                <Input/>
            </Form.Item>            
            <Form.Item label="E-Mail" name="email" 
                rules={[
                    { type: 'email', message: 'E-Mail Adresse nicht gültig' },
                    { required: true, message: "" }]}>
                <Input type={"email"}/>
            </Form.Item>
            <Form.Item label="Telefonnummer" name="phone" rules={[{ required: true, message: "" }]}>
                <Input type={"tel"}/>
            </Form.Item>
            <Form.Item label="Personen" name="peopelCount" rules={[{ required: true, message: "" }]} >
                <Select defaultValue={peopleCountURL} onChange={(event) => setPeopelCount(event)}>
                                {options}
                </Select>
                
            </Form.Item> 
            <Form.Item label="Terminvorschläge" name="picketDate">
                <Select allowClear>
                    {dateOptions}
                </Select>
            </Form.Item>   
            <Form.Item
                label="oder individueller Wunschtermin"
                name="desiredDate"
            >               
                <Input />
            </Form.Item>    
            <Form.Item label={"Weiter Wünsche"} name="comment">
                <TextArea autoSize={{ minRows: 5, maxRows: 8 }}/>
            </Form.Item>
            <Form.Item name="privacyAccepted" valuePropName="checked" rules={[{ required: true, message: "" }]}>
                <Checkbox onChange={(event) => setButtonDisabled(!event.target.checked) }><span style={{color: "#ff4d4f"}}>*</span> Ich bin damit einverstanden, dass meine hier eingegebenen Daten ausschließlich zur Weiterverarbeitung und Kontaktaufnahme durch die Firma Daurer zur Vereinbarung eines Schießtermines genutzt werden. Auf Anfrage werden Ihre Daten aus unserem System gelöscht.</Checkbox>
            </Form.Item>
            
            <Form.Item > 
            <Media queries={{
                small: " (max-width: 444px)",
                large: " (min-width: 445px)"
                }}>
                {matchesCapther => (
                    <>                                            
                    {matchesCapther.large && 
                        <ReCAPTCHA
                        size={ "normal"}
                        sitekey={settings.eventSettings.googleCaptchaKey}
                        onChange={(value) => {setReCaptchaValue(value)}}
                    />}
                    {matchesCapther.small && 
                        <ReCAPTCHA
                        size={ "compact"}
                        sitekey={settings.eventSettings.googleCaptchaKey}
                        onChange={(value) => {setReCaptchaValue(value)}}
                    />}                                        
            </>
        )}
        </Media>   
            <Divider/>   
             
            <table className={styles.priceTable}>
                <tr>
                    <td>Basispreis (Schießstand, Aufsicht, Schutzausrüstung)</td>
                    <td>{formatToEuro(calculatedPrice.basPrice)}</td>
                </tr>
                <tr>
                    <td>Waffen</td>
                    <td>{formatToEuro(calculatedPrice.weaponsPrice)}</td>
                </tr>
                <tr>
                    <td>Zwischensumme</td>
                    <td>{formatToEuro(calculatedPrice.subtotal)}</td>
                </tr>                
                <tr>
                    <td>Gruppenrabatt</td>
                    <td>- {formatToEuro(calculatedPrice.groupDiscount)} </td>
                </tr>                
                <tr>
                    <td>Summe (pro Person)</td>
                    <td>{formatToEuro(calculatedPrice.totalPP)}</td>
                </tr>                              
                <tr>
                    <td>Summe (Gesamt)</td>
                    <td>{formatToEuro(calculatedPrice.total)}</td>
                </tr>
                </table>
                <div className={styles.mwst}>
                    Alle Preise verstehen sich inkl. der gesetzl. MwSt.
                </div>
            </Form.Item>
            <Form.Item shouldUpdate >                
                <div className={styles.modalButtonWrapper}><Button onClick={() => form.submit()} text="Schießtraining anfragen" dark disabled={!isValid || buttonDisabled || reCaptchaValue == null || weapons.length < 1 }/></div>
            </Form.Item>
        </Form>
    )

    return ( <Modal
                title={(<div className={styles.modalTitle}>Warenkorb</div>)}
                centered
                visible={visible}
                onCancel={e => {
                    e.stopPropagation();
                    setStep(0)
                    onCancel()}
                }
                width={"80%"}
                className={styles.eventModal}
                footer={null}
                destroyOnClose>   
                    <Media queries={{
                    small: " (max-width: 1000px)",
                    large: " (min-width: 1001px)"
                    }}>
                    {matches => (
                        <>                                            
                            {matches.small && (
                                step == 0 ? 
                                    <div>
                                        
                                        {selectedWeaponsList}
                                    
                                        <div className={styles.forwardButtonWrapper}>
                                            <Button onClick={() => {setStep(1)}} text="Weiter" dark/>
                                        </div>
                                    </div>
                                :
                                    <>                                                                              
                                        {inputForm}  
                                        <div className={styles.backButtonWrapper}>
                                            <Button onClick={() => {setStep(0)}} text="Zurück" dark/>  
                                        </div>                                  
                                    </>
                                 )
                            }
                            {matches.large && 
                                <>
                                    <div className={styles.wrapperModal}>                                        
                                        {selectedWeaponsList}
                                        {inputForm}                     
                                    </div>
                                </>
                            }                                        
                        </>
                    )}
                    </Media>   
                
        </Modal> 
    );

}
