import { IWeapon } from "./IWeapon";

export interface IEventBooking{
    packageName: string,
    packageId: string,
    name: string
    email: string,
    phone: string,
    comment: string,
    privacyAccepted: boolean,
    timestamp: string,
    peopelCount: number,
    desiredDate: string,
    picketDate: string
}

export function createEventBooking (options?: Partial<IEventBooking>): IEventBooking {
    return Object.assign(
        {
            packageName: "",
            packageId: "",
            name: "",
            email: "",
            phone: "",
            comment: "",
            privacyAccepted: false,
            timestamp: "",
            peopelCount: -1,
            desiredDate: "",
            picketDate: ""
        },options
    );
}

export interface IEventBookingIndividuell{
    name: string
    email: string,
    phone: string,
    comment: string,
    privacyAccepted: boolean,
    timestamp: string,
    peopelCount: number,
    weapons: string[],
    desiredDate: string,
    picketDate: string
}

export function createEventBookingIndividuell (options?: Partial<IEventBookingIndividuell>): IEventBookingIndividuell {
    return Object.assign(
        {
            name: "",
            email: "",
            phone: "",
            comment: "",
            privacyAccepted: false,
            timestamp: "",
            peopelCount: 1,
            weapons: [],
            desiredDate: "",
            picketDate: ""
        },options
    );
}

