import React from "react";
import styles from "./BannerEvent.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Button } from "../Button/Button";
import { routes } from "../../routes";

export const BannerEvent: React.FC<{scrollPositionTop: boolean, setNavigationVisible: (newState: boolean) => void, placeStr?: string}> = ({scrollPositionTop, setNavigationVisible, placeStr = "Rosenheim"}) => {

    
    return (
    <div className={classNames(styles.bannerWrapper, scrollPositionTop ? styles.bannerWrapperFullHeight : styles.bannerWrapperSetHeight)} style={{height: !scrollPositionTop ? "95vh" : "100vh"}}>  
        <div className={styles.infoWrapper}>    
            <div className={styles.infoTitel}>{("Scharf schießen " + placeStr).toUpperCase()}</div>  
            <div className={styles.infoLowerTitel}>{("Ohne Waffenschein - bei Waffen Daurer").toUpperCase()}</div>         
           <div className={styles.bannerButtonWrapper}> <Link to={{ pathname: routes.eventPackages }}  onClick={() => setNavigationVisible(false)}><Button  onClick={() => {}} text="Pakete" dark={false} big/> </Link></div>
        </div>       
       <div style={{background: `url(https://daurer.de/images/event/frontBanner.jpg) center center / cover`, height: !scrollPositionTop ? "95vh" : "100vh"}} className={styles.picture}></div>
        {/*<img src={banner} alt="logo" className={styles.logo}/>*/}
                          
    </div>);

}
