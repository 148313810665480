export enum routes {
  home = "/",
  event = "/event",
  eventMuc = "/event/muenchen",
  eventOB = "/event/oberbayern",
  eventBay = "/event/bayern",
  eventPackages = "/event/pakete",
  eventIndividuall = "/event/pakete/individuell",
  eventQundA = "/event/qanda",
  coursesTmp = "/lehrgaengetmp",
  courses = "/lehrgaenge",
  coursesDetails = "/lehrgaenge/:name",
  shootingRange = "/schiessstand",
  shootingRange25 = "/schiessstand/_25",
  shootingRange100 = "/schiessstand/_100",
  workshop = "/werkstatt",
  imprint = "/impressum",
  privacyStatement = "/datenschutz",
}
