import React from "react";
import _ from 'lodash';
import { IQuestionAndAwnser } from "../../model/IQuestionAndAwnser";
import { Collapse } from 'antd';
import styles from "./QuestionsAndAwnsers.module.css";
import { Title } from "../../components/Title/Title";
import { SafetyItem } from "../../components/SafetyItem/SafetyItem";
import { MailOutlined, PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";

const { Panel } = Collapse;


export const QuestionsAndAwnsers: React.FC<{questionsAndAwnsers: IQuestionAndAwnser[]}> = ({questionsAndAwnsers}) => {


    return (
    <div className={styles.qandaWrapper}>     
    
        <Title text="Q&A" dark />    
        <Collapse bordered={false} className={styles.collapsStyle}>
            {
                questionsAndAwnsers.map((qAndA, index) => (
                    <Panel header={<div className={styles.questionHeader}>{qAndA.question}</div>} key={`q-and-a-${index}`}>
                        {qAndA.awnsers.map(awnser => (<SafetyItem text={awnser} dark/>))}
                    </Panel>
                ))
            }            
        </Collapse>
        <div className={styles.anyQuestionsWrapper}>
            <div className={styles.anyQuestionsHeader}>Noch weitere Fragen?</div>
            <div className={styles.anyQuestionsSubHeader}>Dann schreib uns eine Nachricht, ruf uns an oder informiere Dich bei uns im Laden.</div>
            <div><WhatsAppOutlined /> <a href={"https://wa.me/" + "+49803132559"} target="_blank" className={styles.contactLink}> +49 8031 32559</a></div>                
            <div><PhoneOutlined /><a href="tel:0803132559" className={styles.contactLink}> 08031 32559</a> </div>               
            <div><MailOutlined /><a href="mailto:­waffen@daurer.de" className={styles.contactLink}>­ waffen@daurer.de</a></div><br/>
        </div>
        
    </div>);

}
