
import React from "react";
import styles from "./ShootingRange.module.css";
import _ from 'lodash';
import { IShootingRange } from "../../model/IShootingRange";
import { Title } from "../Title/Title";
import { Carousel, Divider } from "antd";
import { AimOutlined, RightOutlined } from "@ant-design/icons";
import { routes } from "../../routes";
import { EventAd } from "../EventAd/EventAd";
import { Button } from "../Button/Button";
import classNames from "classnames";

export const ShootingRange: React.FC<{shootingRange: IShootingRange}> = ({shootingRange}) => {



    return (   
        <div className={styles.itemWrapper}>
            <div className={styles.bannerCarouselWrapper}>
                <Carousel  autoplay dots={false}>
                        {shootingRange.bannerUrls?.map((url) => 
                                <img src={url} alt="img" className={styles.carouselImg} /> 
                            )}                    
                </Carousel> 
                <div  className={styles.bannerButtonWrapper}> 
                    <div onClick={() => window.location.href = routes.shootingRange100} className={classNames(styles.bannerButtonItem, shootingRange.length == 100 ?  styles.bannerButtonSelected : undefined)}>
                        <div className={styles.bannerTextWrapper} > {("100 Meter").toUpperCase()}</div>                   
                    </div> 
                    <div onClick={() => window.location.href = routes.shootingRange25} className={classNames(styles.bannerButtonItem,  shootingRange.length == 25 ?  styles.bannerButtonSelected : undefined)}>
                        <div className={styles.bannerTextWrapper}  > {("25 Meter").toUpperCase()}</div>
                    </div> 
                </div>
            </div>
           
            <Title text={`${shootingRange.length} meter Schießstand`} dark/>
            <div className={styles.descWrapper}>
                <div>{shootingRange.description}</div>
                <div className={styles.adWrapper}><EventAd/></div>
                <div>
                    <Divider orientation="left" style={{border:'#282828', fontWeight: 550, fontSize: "16px", color: "rgb(51, 51, 51)"}}>Preise</Divider> 
                    <div className={styles.priceInfo}>Mit eigener Aufsicht und absolvierter Waffensachkundeprüfung.</div>
                    <div className={styles.priceGridWrapper}>
                            <div className={styles.priceLeft}>
                                <table className={styles.pricesTable}>
                                    <tr><td>Eine Bahn (maximal 2 Personen)</td></tr>
                                    {
                                        shootingRange.pricesOneLane.map((price)=> 
                                            (<tr>
                                                <td><RightOutlined className={styles.iconPrice}/> {price.duration} Stunde{price.duration != 1 && "n"}</td>
                                                <td>{price.price} €</td>
                                            </tr>))
                                    }
                                </table>                                              
                            </div>
                            <div className={styles.priceRight}>
                                <table className={styles.pricesTable}>
                                    <tr><td>Ganzer Stand (5 Bahnen)</td></tr>
                                    {
                                        shootingRange.pricesFiveLanes.map((price)=> 
                                            (<tr>
                                                <td><RightOutlined className={styles.iconPrice}/> {price.duration} Stunde{price.duration != 1 && "n"}</td>
                                                <td>{price.price} €</td>
                                            </tr>))
                                    }
                                </table> 
                            </div>
                        </div>
                    </div>
            </div> 
                    
            <div className={styles.darkWrapper}>                
                <Title text={`Zusatzleistungen`} white/>
                <div className={styles.additionalServicesWrapper}>    
                        {
                            shootingRange.additionalServices.map((service)=> 
                                (<div><AimOutlined className={styles.iconPrice}/> {service.description}<br/>
                                    <table className={styles.additionalServicesTable}>
                                        {
                                            service.prices.map((price)=> 
                                                (<tr>
                                                    <td className={styles.priceLable}>
                                                        {(price.duration ? price.duration + ` Stunde${price.duration != 1 ? "n": ""}` : (price.additionalInfo || ""))}{price.duration && `\n${price.additionalInfo}`} {service.additionalDesc && `\n${service.additionalDesc}`}
                                                    </td>
                                                    <td>{price.price != -1 && price.price + " €"}</td>
                                                </tr>))
                                        }
                                    </table> 
                                </div>))
                        }
                                           
                </div>
            </div>
            <div className={styles.wrapper}>
                <Title text={`Hinweise`} dark/>
                <div className={styles.regulationsWrapper}>
                    <table className={styles.regulationsTable}>
                                {/*<tr><td  className={styles.linkRegulations}>Es gilt die <a href={""} target="_blank">Schießstandordnung</a> und alle Sicherheitshinweise sind zu beachten.</td></tr>*/}
                                {
                                    shootingRange.regulations.map((rule)=> 
                                        (<tr>
                                            <td><div><div><RightOutlined className={styles.iconPrice}/></div> <div className={styles.ruleWrapper}>{rule}</div></div></td>
                                        </tr>))
                                }
                    </table> 
                </div>
            </div>
                
        </div>);

}
