  import { AimOutlined, InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Breadcrumb, Empty, Select, Tooltip } from "antd";
import classNames from "classnames";
import _ from "lodash";
import React, { useState } from "react";
import Media from "react-media";
import { IAppSettings } from "../../../model/IAppSettings";
import { createPackage, getPeopleCosts, IPackage } from "../../../model/IPackage";
import { IWeapon } from "../../../model/IWeapon";
import { formatTime, formatToEuro } from "../../../shared/formatter";
import { BookingModal } from "../BookingModal/BookingModal";
import { Button } from "../../Button/Button";
import { GeneralInfoComponent } from "../../GeneralInfoComponent/GeneralInfoComponent";
import ImageCarousel from "../../ImageCarousel/ImageCarousel";
import ImgOverviewCarousel from "../../ImgOverviewCarousel/ImgOverviewCarousel";
import { PeoplePicker } from "../PeoplePicker/PeoplePicker";
import { SafetyComponent } from "../../SafetyComponent/SafetyComponent";
import { Title } from "../../Title/Title";
import { WeaponListItem } from "../WeaponListItem/WeaponListItem";
import styles from "./PackageDetails.module.css";
import { Directions } from "../../Directions/Directions";

export const PackageDetails: React.FC<{packages: IPackage[], weapons: IWeapon[], settings: IAppSettings}> = ({packages, weapons, settings}) => {
          
    const getId = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get("id");
    }

    const id = getId();
    const packageItem = packages.find(pack => pack.id == id) ?? createPackage();
    const [peopleCosts, setPeopleCosts] = useState(getPeopleCosts(packageItem));

    const [selectedImg, setSelectedImg] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    

    const getWeaponById = (id: number) => {
        const index = weapons?.findIndex(weapon => weapon.id == id);
        if(index != -1){
            return weapons[index];
        }
        return null;
    }

    const includesWeapons = weapons.filter(weapon => packageItem?.weapons.findIndex(weaponRef => weaponRef.id == weapon.id) != -1);

    if(packageItem == undefined){
        return(
            <div className={styles.emptyWrapper}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Paket nicht gefunden"}></Empty>
            </div>
        );
    }

    return (
     <Media queries={{
          small: " (max-width: 999px)",
          large: "(min-width: 1000px)"
        }}>
          {matches => (
            <div className={styles.wholeDetailsWrapper}>
                <BookingModal visible={modalVisible} packageItem={packageItem} onCancel={() => setModalVisible(false)} settings={settings} selectedPeople={peopleCosts}/>
                <div className={styles.detailsWrapper}>
                    <div className={styles.filterWrapper}>
                        <Breadcrumb>
                            <Breadcrumb.Item>                
                                <a className={styles.breadcrumb} href="/event">Überischt</a></Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a className={styles.breadcrumb} href="/event/pakete">Fertige Pakete</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{packageItem.title}</Breadcrumb.Item>                
                        </Breadcrumb>
                        {matches.small ? <Title text={packageItem?.title ?? ""} dark/> : <></>}
                    <div/>
                </div> 
                    <div className={styles.imgWrapper}>
                        <ImageCarousel packageItem={packageItem ?? createPackage()} selectedImg={selectedImg} onImgChange={setSelectedImg} includesWeapons={includesWeapons}/>
                    </div>
                    <div className={styles.imgOverviewWrapper}>
                        {
                           [...packageItem.imgURLs, ...(includesWeapons.map((we) => we.imgUrl))].map((url, index) => (
                                <img src={url} alt="img" className={classNames(styles.imgOverview)} onClick={() => setSelectedImg(index)} key={`weaponImg#${index}.${index}`}/>
                                
                                ))
                        }
                    </div>  
                    <div className={styles.detailInfoWrapper}>
                        {matches.large ? <Title text={packageItem?.title ?? ""} dark/> : <></>}
                        <div className={styles.itemShortInfo}>{packageItem?.shortInfo}</div>
                        <div className={styles.itemShortInfo}>{formatTime(peopleCosts?.duration ?? -1)}</div><br></br>
                        {packageItem?.weapons.map((weaponRef, index) => 
                        { const weapon = getWeaponById(weaponRef.id); 
                            return (<div className={styles.infoItem} key={`weapon#${index}.${index}`}>
                                <AimOutlined style={{marginTop: "4px"}}/>
                                <div> {weapon?.name}
                                {
                                    weaponRef.shots != -1 ? ` (max. ${weaponRef.shots} Schuss pro Person)` : ""
                                }
                                 </div>
                                <div id="filler"></div>
                                <div> Kaliber: {weapon?.caliber}</div>
                            </div> ) }              
                        )} <PeoplePicker value={peopleCosts} packageItem={packageItem} onChange={setPeopleCosts}/>
                        <div className={styles.itemButtonPriceWrapper}>
                            <div className={styles.itemPrice}>{formatToEuro(peopleCosts?.price ?? -1)} </div>
                            <div className={styles.itemButton}><Button onClick={() => {setModalVisible(true)}} text="Paket buchen" dark/> </div> 
                            <div className={styles.itemPerPerson}>pro Person</div> 
                        </div>             
                    </div>                         
                </div>

                <div className={styles.eventProcessWrapper}>
                    <Title text={"Ablauf"}/>
                    <div className={styles.eventProcessItemWrapper}><RightOutlined className={styles.eventProccesIcon}/><div className={styles.eventProccesText}>Anmeldung im Waffengeschäft</div></div>
                    <div className={styles.eventProcessItemWrapper}><RightOutlined className={styles.eventProccesIcon}/> <div className={styles.eventProccesText}>Sicherheitsinstruktion auf dem Schießstand</div></div>
                    <div className={styles.eventProcessItemWrapper}><RightOutlined className={styles.eventProccesIcon}/> <div className={styles.eventProccesText}>Einweisungen an den Waffen</div></div>
                    <div className={styles.eventProcessItemWrapper}><RightOutlined className={styles.eventProccesIcon}/> <div className={styles.eventProccesText}>Platzierung in Schießposition und Testen des Abzuges</div></div>
                    <div className={styles.eventProcessItemWrapper}><RightOutlined className={styles.eventProccesIcon}/> <div className={styles.eventProccesText}>Schießen der gebuchten Waffen</div></div>
                    <div className={styles.eventProcessItemWrapper}><RightOutlined className={styles.eventProccesIcon}/> <div className={styles.eventProccesText}>Auf Wunsch Fotos mit den Waffen</div></div>
                    <div className={styles.eventProcessItemWrapper}><RightOutlined className={styles.eventProccesIcon}/> <div className={styles.eventProccesText}>Hülsen und Zielscheiben können gerne als Andenken mitgenommen werden</div></div>
                </div>

                <div className={styles.weaponsWrapper}>
                    <Title text={"Waffen"} subtitle="Diese Waffen warten auf Dich" dark/>
                    <div className={styles.weaponsSubText}>Ist eine Waffe aktuell nicht verfügbar, kann es vorkommen, dass wir sie zu einer gleichwertigen oder höherwertigen upgraden.</div>
                    {
                        includesWeapons.map((weapon, index) => (<WeaponListItem weapon={weapon}/>))
                    }
                </div>  

                <SafetyComponent/>
                <Directions/>  
            </div>
          )}
        </Media>    
    );

}
