import { Carousel, Divider } from "antd";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { ServiceItem } from "../ServiceItem/ServiceItem";
import { Title } from "../Title/Title";
import styles from "./Services.module.css";
import { routes } from "../../routes";

export const Services: React.FC<{}> = ({}) => {
   


    const revealLogo = (event:any) => {
        var logo = document.getElementById("logoSlider");
        if(logo != null){
            var windowHeight = window.innerHeight;
            var elementTop = logo.getBoundingClientRect().top;
            var elementVisible = 150;
        
            if (elementTop < windowHeight - elementVisible) {
                logo.classList.add(styles.logoSliderAnimation);
            } else {                
                logo.classList.remove(styles.logoSliderAnimation);
            }
        }
        
    }
    
    window.addEventListener("scroll", revealLogo);

    const thisRef = useRef<HTMLDivElement>(null);    

    return (
        <div className={styles.serviceWrapper}ref={thisRef} >     
            <Title text="Unsere Services" />
            <div className={styles.contentWrapper}>
                <div  className={styles.logoWrapper}>            
                    <img id="logoSlider" className={classNames(styles.logoSlider)} src="https://daurer.de/images/slider/logo_slider.png"/>
                </div>
                <div className={styles.servicesWrapper}>
                    <ServiceItem title="Kurz- und Langwaffenschießstand" text={<>Sie können bei uns auf 5 Bahnen im Kurzwaffenstand und auf 5 Bahnen auf dem Langwaffenstand nach Terminvereinbarung schießen. Mehr informationen gibt es auf der<a href={routes.shootingRange} target="_blank"> Schießstandseite</a>.</>}/>
                    <ServiceItem title="MEISTERWERKSTATT SEIT 1848 BÜCHSENMACHERARBEITEN" text="Wir bieten Büchsenmacherarbeiten aller Art an. Das Fachgerechte Montieren von Zielvernrohren und Schalldämpfern mit allen nötigen Vor- und Nachbereitungen ist eins unserer Spezialgebiete." />
                    <ServiceItem title="LEHRGÄNGE" text={<>Wir bieten verschiedene Lehrgänge für Sportschützen, Sicherheitsdienste, Vorderladerschießen und Wiederladen an. Die Formulare finden sie auf der<a href={routes.courses} target="_blank"> Lehrgangsseite</a>.</>}/>
                    <ServiceItem title="SCHIESS-EVENTS" text={<>Wir bieten Events zum scharf schießen an - ganz ohne Waffenschein. Für mehr Infos besuche einfach unsere <a href={routes.event} target="_blank"> Eventsseite</a>.</>}/>
                    <ServiceItem title="JAGDÜBUNGEN" text="Sie haben die Möglichkeit bei uns auf Kipphase, laufeden Keiler und angestrichen zu schießen." />
                    <ServiceItem title="FACHBERATUNG" text="WIr nehmen uns Zeit für unsere Kunden. Praxisbezoge Beratung in allen Belangen für Jagd und Sport wird bei uns groß geschrieben." />
                </div>
            </div>
           
               
    </div>);

}
