import React, { useContext } from "react";
import styles from "./IndividuellPricingModal.module.css";
import { IndiviualPricingContext } from "../../App";
import { Modal } from "antd";
import { formatToEuro } from "../../shared/formatter";

export const IndividuellPricingModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const indiviualPricing = useContext(IndiviualPricingContext);

  return (
    <Modal title="Preisübersicht" className={styles.pricingModal} visible={isOpen} onCancel={onClose} footer={null}>
      <table className={styles.wrapperTable}>
        <tr>
          <td>Basispreis (Schießstand, Aufsicht, Schutzausrüstung)</td>
          <td>{formatToEuro(indiviualPricing.basePrice)}</td>
        </tr>
        <tr>
          <td>Waffen</td>
        </tr>
        <tr>
          <table className={styles.weaponPrcingTable}>
            <tr>
              <th>Kategorie</th>
              <th>Preis je Waffe</th>
            </tr>
            <tr>
              <td>Bronze</td>
              <td>{formatToEuro(indiviualPricing.weaponCategroies[0].price)}</td>
            </tr>
            <tr>
              <td>Silber</td>
              <td>{formatToEuro(indiviualPricing.weaponCategroies[1].price)}</td>
            </tr>
            <tr>
              <td>Gold</td>
              <td>{formatToEuro(indiviualPricing.weaponCategroies[2].price)}</td>
            </tr>
          </table>
        </tr>
        <tr>
          <td>Gruppenrabatte</td>
        </tr>
        <tr>
          <table className={styles.weaponPrcingTable}>
            <tr>
              <th>Personenanzahl</th>
              <th>Rabatt</th>
            </tr>
            {indiviualPricing.peopleCountSpecs.slice(1).map((cost) => (
              <tr>
                <td>{cost.count}</td>
                <td>{Math.round((1 - cost.discountFraction) * 100)} %</td>
              </tr>
            ))}
          </table>
        </tr>
      </table>

      <div className={styles.mwst}>Alle Preise verstehen sich inkl. der gesetzl. MwSt.</div>
    </Modal>
  );
};
