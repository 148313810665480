import { ClockCircleOutlined, InfoCircleOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Badge, Breadcrumb, Carousel, Drawer, Space, Tooltip } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { IAppSettings } from "../../model/IAppSettings";
import { IPackage } from "../../model/IPackage";
import { IWeapon } from "../../model/IWeapon";
import styles from "./EventPackages.module.css";
import { PackageListIndividuall } from "../../components/PackageComponents/PackageListIndividuall/PackageListIndividuall";
import { PackageList } from "../../components/PackageComponents/PackageList/PackageList";
import { trackPage } from "../../GATools";

export const EventPackages: React.FC<{packages: IPackage[], weapons: IWeapon[], settings: IAppSettings, isIndivduall: boolean}> = ({packages, weapons, settings, isIndivduall}) => {

       

    const loadPickedWeapons = ():IWeapon[] => {
        const params = new URLSearchParams(window.location.search);
        const pickedWeaponIDs = params.get("ws");
        const pickedWe = [] as IWeapon[];
        pickedWeaponIDs?.split("|").forEach((id) => {
            const index = weapons.findIndex(w => w.id.toString() == id );
            index != -1 && pickedWe.push(weapons[index]);
        });
        return pickedWe;
    }
    
    const [pickedWeapons, setPickedWeapons] = useState(loadPickedWeapons());

    let navigate = useNavigate();
    const onImageClick= (urlStr: string, pickedWeapons: IWeapon[] = []) => { 
        const parameters = pickedWeapons.length == 0 ? "": "?ws="+ pickedWeapons.map(we => we.id).join("|")    
        navigate(`/event/pakete${urlStr + parameters}`, {replace: false});
        trackPage()
        window.scrollTo(0,0 );
             
    }   

    return (<div className={styles.packagesWrapper}>        
        <div className={styles.packageListBanner}>
            <div className={styles.packageListBannerCarousel}>
            <Carousel className={styles.carouselStyle} autoplay dots={false}>
                {
                    settings.eventSettings.packageListBannerUrls?.map((url) => 
                        <img src={url} alt="img" className={styles.carouselImg} /> 
                    )
                }
                
          </Carousel>
          </div>
                <div  className={styles.bannerButtonWrapper}>
                <div className={classNames(styles.bannerButtonItem, !isIndivduall ?  styles.bannerButtonSelected : undefined)} onClick={() => onImageClick("")}>
                    <div className={styles.bannerTextWrapper}> {("Fertige Pakete").toUpperCase()}</div>                   
                </div> 
                <div className={classNames(styles.bannerButtonItem, isIndivduall ?  styles.bannerButtonSelected : undefined)}onClick={() => onImageClick("/individuell", pickedWeapons)}>
                    <div className={styles.bannerTextWrapper}  > {("Individuell").toUpperCase()}</div>
                </div>  
                </div>
                            
        </div>       
            <Breadcrumb className={styles.breadCrumbs} >
                    <Breadcrumb.Item>                
                        <a className={styles.breadcrumb} href="/event">Übersicht</a></Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a className={styles.breadcrumb} href="/event/pakete">{!isIndivduall ? "Fertige Pakete" : "Individuell"}</a>
                    </Breadcrumb.Item>                                  
                </Breadcrumb>
         
        {
            !isIndivduall ? 
             <PackageList packages={packages} weapons={weapons}/>
             :
           <PackageListIndividuall weapons={weapons} settings={settings} pickedWeapons={pickedWeapons} setPickedWeapons={setPickedWeapons}/>
        }
       
    </div>);

}

