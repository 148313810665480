import React from "react";
import styles from "./Directions.module.css";
import _ from 'lodash';
import { Title } from "../Title/Title";
import { ContactComponent } from "../ContactComponent/ContactComponent";
import { Anchor } from "../Anchor/Anchor";
export const Directions: React.FC<{}> = ({}) => {

    return (
        <div className={styles.mapWrapper}>  
         
         <Anchor idTag="contact"/>               
            <Title text="ANFAHRT" dark />
            <div className={styles.mapInfoWrapper}>
                <div className={styles.mapFrame}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236.6238096695174!2d12.129260684220426!3d47.85716585542633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47761a4874dfc8c1%3A0x16aae93d9220eba9!2sWaffen%20Daurer!5e0!3m2!1sde!2sde!4v1641214613699!5m2!1sde!2sde" width="100%" height="450" style={{"border" : "0"}} allowFullScreen={false} loading="lazy"></iframe>
                </div>
                <div className={styles.adressWrapper}>  
                    <ContactComponent/>                        
                </div> 
            </div>              
        </div>);
}
