import moment from "moment";
import { ICourseDate } from "../model/ICourse";

export const EMPTYGUID = "00000000-0000-0000-0000-000000000000";

export const isEvanNumber = (num: number):boolean => {
    return num % 2 == 0;
}

export const isValidEmail = (email:string) => {
    const emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);    
    return  emailRegEx.test(email);  
}

export const formatToEuro = (euroNumber: number):string => {    
    const formatter = Intl.NumberFormat("de-DE", {style: 'currency', currency: 'EUR', minimumFractionDigits: 2});
    return formatter.format(euroNumber);

};


const weekDays = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
export const getCourseDateString = (courseDate: ICourseDate) => {
    return weekDays[moment(courseDate.date).day()] + moment(courseDate.date).format(". DD.MM.YYYY ~ ") + moment(courseDate.start).format("HH:MM-") +moment(courseDate.end).format("HH:MM ") + "Uhr"
}

export const formatTime = (minutesNuber: number):string => {    
    const hours = Math.floor(minutesNuber / 60) ;
    const minutes = minutesNuber - (hours * 60);

    const hourStr = `${hours} Stunde${hours > 1 ? "n" : ""}`;
    const minuteStr = `${minutes} Minute${minutes > 1 ? "n" : ""}`;

    if(hours == 0){
        return minuteStr;
    }
    else if(minutes == 0){
        return hourStr;
    }
    return `${hourStr} ${minuteStr}`;    

};