import React from "react";
import styles from "./Home.module.css";
import _ from 'lodash';
import { History } from "../../components/History/History";
import { Services } from "../../components/Services/Services";
import { IHistory } from "../../model/IHistory";
import { Team } from "../../components/Team/Team";
import { ITeam } from "../../model/ITeam";
import { Directions } from "../../components/Directions/Directions";

export const Home: React.FC<{history: IHistory[], team: ITeam[]}> = ({ history, team}) => {

        
    return (
    <div className={styles.homeWrapper}> 
        <Services/>        
        <History history={history}/> 
        <Team team={team}/>
        <Directions/>
    </div>);

}
