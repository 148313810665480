import React from "react";
import styles from "./Anchor.module.css";

export const Anchor: React.FC<{idTag: string}> = ({idTag}) => {

    return (
        <span className={styles.wrapper}>
            
            <span className={styles.content} id={idTag}></span> 
        </span>
    );

}
